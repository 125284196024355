import Paragraph from "@tiptap/extension-paragraph";


const UserParagraph = Paragraph.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            'data-type': {
                default: 'user_toice',
                renderHTML: attributes => {
                    return { 'data-type': attributes['data-type'] || 'user_toice' } // Use default if not provided
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'p[data-type]',
                getAttrs: element => { 
                    const content = element.innerHTML.trim(); // Ensure no empty content is rendered
                    return content ? {'data-type': element.getAttribute('data-type') || 'user_toice' } : false;
                },
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return this.options.HTMLAttributes || !HTMLAttributes['data-type'] ? '' : ['p', { ...HTMLAttributes }, 0];
    },
});

export default UserParagraph;

