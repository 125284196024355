import { Node } from '@tiptap/pm/model'
import { NodeSelection, TextSelection } from '@tiptap/pm/state'
import { Editor } from '@tiptap/react'
import { useCallback } from 'react'

const useContentItemActions = (editor: Editor, currentNode: Node | null, currentNodePos: number) => {
  const resetTextFormatting = useCallback(() => {
    const chain = editor.chain()

    chain.setNodeSelection(currentNodePos).unsetAllMarks()

    if (currentNode?.type.name !== 'paragraph') {
      chain.setParagraph()
    }

    chain.run()
  }, [editor, currentNodePos, currentNode?.type.name])

  const duplicateNode = useCallback(() => {
    if (!currentNode) {
      console.warn('Cannot duplicate a null node.');
      return;
    }
    editor.commands.setNodeSelection(currentNodePos)

    const { $anchor } = editor.state.selection
    const selectedNode = $anchor.node(1) || (editor.state.selection as NodeSelection).node;

    if (!selectedNode) {
      console.warn('No node selected for duplication.');
      return;
    }

    editor
      .chain()
      .setMeta('hideDragHandle', true)
      .insertContentAt(currentNodePos + (currentNode?.nodeSize || 0), selectedNode.toJSON())
      .run()
  }, [currentNode, editor, currentNodePos])

  const copyNodeToClipboard = useCallback(() => {
    if (!currentNode) {
      console.warn('Cannot copy a null node to clipboard.');
      return;
    }
    editor.chain().setMeta('hideDragHandle', true).setNodeSelection(currentNodePos).run()

    document.execCommand('copy')
  }, [editor, currentNodePos])

  const deleteNode = useCallback(() => {
    editor.chain().setMeta('hideDragHandle', true).setNodeSelection(currentNodePos).deleteSelection().run()
  }, [editor, currentNodePos])

  const handleAdd = useCallback(() => {
    if (!currentNode) {
      console.warn('Cannot add after a null node.');
      return;
    }
    if (currentNodePos !== -1) {
      const currentNodeSize = currentNode?.nodeSize || 0;
      const insertPos = currentNodePos + currentNodeSize;
      const currentNodeIsEmptyParagraph = currentNode?.type.name === 'paragraph' && currentNode?.content?.size === 0;
      const focusPos = currentNodeIsEmptyParagraph ? currentNodePos + 2 : insertPos + 2;
  
      editor
        .chain()
        .command(({ dispatch, tr, state }) => {
          if (dispatch) {
            if (currentNodeIsEmptyParagraph) {
              tr.insertText('/', currentNodePos, currentNodePos + 1);
            } else {
              tr.insert(insertPos, state.schema.nodes.paragraph.create(null, state.schema.text('/')));
            }
  
            dispatch(tr);
          }
  
          return true;
        })
        .focus(focusPos)
        .run();
  
      editor.on('selectionUpdate', ({ editor }) => {
        const { selection } = editor.state;
        const currentPos = selection.$anchor.pos;

        
  
        // Prevent the cursor from moving to the end of the document
        editor.chain().setNodeSelection(TextSelection.near(editor.state.doc.resolve(currentPos)).$anchor.pos).focus().run();
      });
    }
  }, [currentNode, currentNodePos, editor]);
  

  return {
    resetTextFormatting,
    duplicateNode,
    copyNodeToClipboard,
    deleteNode,
    handleAdd,
  }
}

export default useContentItemActions
