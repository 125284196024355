import { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

export interface AuthContextProps {
    authToken: string | null;
    login: (token: string, user: string) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    
    const [cookie, setCookie, removeCookie] = useCookies(['token']);
    const [authToken, setAuthToken] = useState<string | null>(cookie.token);

    useEffect(() => {
        // const storedToken = localStorage.getItem('token');
        const storedToken = cookie.token;
        // console.log('storedToken', storedToken);
        if(storedToken)
        {
            setAuthToken(storedToken);
        }
    }, [cookie]);

    useEffect(() => {
        if(authToken)
        {
            const expirationTime = getExpirationTime(authToken);
            const currentTime = Date.now();
            const timeUntilExpiration = expirationTime - currentTime;
            
            if(timeUntilExpiration <= 0)
            {
                // logout user if token is expired
                logout();
                notifySessionExpired();
            } else {
                const timer = setTimeout(() => {
                    logout();
                    notifySessionExpired();
                }, timeUntilExpiration);

                return () => clearTimeout(timer);
            }
        }
    }, [authToken]);

    const login = (newToken: string, user: string) => {
        // localStorage.setItem('token', newToken);
        setCookie('token', newToken, { httpOnly: true, secure: true });
        localStorage.setItem('user', user);
        setAuthToken(newToken);
    };

    const logout = () => {
        // localStorage.removeItem('token');
        removeCookie('token');
        localStorage.removeItem('user');
        setAuthToken(null);
    };

    const notifySessionExpired = () => {
        // TODO: replace with more friendly notification
        // alert('Session expired. Please login again');
    }

    const getExpirationTime = (token: string) => {
        const decodedToken = parseJwt(token);
        return decodedToken.exp * 1000;
    };

    // function to parse jwt token
    const parseJwt = (token: string) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    return (
        <AuthContext.Provider value={{ authToken, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};  

