import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react';
import { JSX } from 'react/jsx-runtime';

export const ReactComponentNode = Node.create({
  name: 'reactComponentNode',

  group: 'block',

  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {},
      ReactComponent: null, // React component to render
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div.react-component-node',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { class: 'react-component-node' })];
  },

  addNodeView() {
    return ReactNodeViewRenderer((props: JSX.IntrinsicAttributes) => {
      const { ReactComponent } = this.options;
      return (
        <NodeViewWrapper className="react-component-node">
          <ReactComponent {...props} />
        </NodeViewWrapper>
      );
    });
  },
});
