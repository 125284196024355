import React from 'react';
import { BsReverseLayoutTextSidebarReverse  } from 'react-icons/bs';

interface SidebarToggleProps {
  toggleSidebar: () => void;
}

const SidebarToggle: React.FC<SidebarToggleProps> = ({ toggleSidebar }) => {
  return (
    <button
      onClick={toggleSidebar}
      className="max-md:hidden p-2 text-gray-600 hover:text-gray-800 focus:outline-none"
    >
      <BsReverseLayoutTextSidebarReverse  size={24} />
    </button>
  );
};

export default SidebarToggle;
