import { useTheme } from 'context/ThemeContext';
import React, { useEffect, useState } from 'react';


interface AlertProps {
  type?: 'error' | 'success';
  message: string;
  autoDismiss?: boolean;
  dismissTime?: number;
  onDismiss?: () => void;
}

const Alert: React.FC<AlertProps> = ({ type = 'error', message, autoDismiss = true, dismissTime = 5000, onDismiss }) => {
  const [visible, setVisible] = useState(true);
  const { isDarkMode } = useTheme();

  // Icons and colors based on the alert type
  const icons = {
    error: (
      <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
      </svg>
    ),
    success: (
      <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M16.707 5.707a1 1 0 0 0-1.414-1.414L8 11.586 4.707 8.293a1 1 0 0 0-1.414 1.414l4 4a1 1 0 0 0 1.414 0l8-8Z" />
      </svg>
    ),
  };

  const alertStyles = {
    error: "text-red-800 bg-red-50 dark:bg-gray-800 dark:text-red-400",
    success: "text-green-800 bg-green-50 dark:bg-gray-800 dark:text-green-400",
  };

  // Auto-dismiss after `dismissTime` milliseconds if `autoDismiss` is true
  useEffect(() => {
    if (autoDismiss) {
      const timer = setTimeout(() => {
        setVisible(false);
        if (onDismiss) onDismiss();
      }, dismissTime);

      return () => clearTimeout(timer); // Clean up the timer when component is unmounted
    }
  }, [autoDismiss, dismissTime, onDismiss]);

  // Handle manual dismissal on click
  const handleDismiss = () => {
    setVisible(false);
    if (onDismiss) onDismiss();
  };

  if (!visible) return null; // Don't render if the alert is not visible

  return (
    <div
      id="alert-2"
      className={`${isDarkMode ? 'dark' : '' } flex items-center p-4 mb-2 rounded-lg ${alertStyles[type]} dark:bg-gray-800`}
      role="alert"
    >
      {icons[type]}
      <span className="sr-only">{type === 'success' ? 'Success' : 'Error'}</span>
      <div className="ms-3 text-sm font-medium">{message}</div>
      <button
        type="button"
        className="ms-auto -mx-1.5 -my-1.5 p-1.5 rounded-lg focus:ring-2 focus:ring-red-400 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:hover:bg-gray-700"
        aria-label="Close"
        onClick={handleDismiss}
      >
        <span className="sr-only">Close</span>
        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
        </svg>
      </button>
    </div>
  );
};

export default Alert;
