
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EditorPage from './pages/EditorPage';
import LoginPage from './pages/LoginPage';
import AuthenticatedRoute from './components/auth/PrivateRoute';
import { GuestRoute } from './utils/helper';
import { RegisterPage, VerifyEmailPage } from './pages/RegisterPage';
import LogoutPage from './pages/LogoutPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from 'context/AuthContext';
import { ForgetPassword } from 'components/auth/ForgetPassword/ForgetPassword';
import { FPVerifyOtp } from 'components/auth/ForgetPassword/FPVerifyOtp';
import { NewPassword } from 'components/auth/ForgetPassword/NewPassword';
import { ListProvider } from 'context/ListContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider } from 'context/ThemeContext';
import Homepage from 'pages/Homepage';

const App: React.FC = () => {
  const _CLIENTID = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '';
  return (
    <Router>
      <GoogleOAuthProvider clientId={_CLIENTID}>
      <ThemeProvider>
        <AuthProvider>
          <ListProvider>
            <Layout />
          </ListProvider>
        </AuthProvider>
      </ThemeProvider>
      </GoogleOAuthProvider>
    </Router>
  );
};


const Layout: React.FC = () => {
  // const location = useLocation();

  // const isEditorPage = location.pathname === '/editor';

  return (
    <div>
      {/* {!isEditorPage && <Navbar />} */}
      <Routes>
        <Route element={<AuthenticatedRoute />}>
          <Route path="/editor" element={<EditorPage />} />
          <Route path='/logout' element={<LogoutPage />} />
        </Route>
        
        <Route path="/" element={<Homepage />} />
        <Route element={<GuestRoute />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/register/verify" element={<VerifyEmailPage />} />
          <Route path='/forget-password' element={<ForgetPassword />} />
          <Route path='/forget-password/verify' element={<FPVerifyOtp />} />
          <Route path='/forget-password/new-password' element={<NewPassword />} />
        </Route>
        
      </Routes>
      <ToastContainer />

    </div>
  );
};

export default App;
