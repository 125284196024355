import { Editor } from "@tiptap/react";
import React from "react";
import { FaBold, FaItalic, FaUnderline, FaStrikethrough, FaListOl, FaListUl } from "react-icons/fa6";
import { LuHeading1, LuHeading2, LuHeading3 } from "react-icons/lu";
import { TfiAlignCenter, TfiAlignLeft, TfiAlignRight } from "react-icons/tfi";

export const BubbleMenuButton: React.FC<{ editor: Editor }> = ({ editor }) => {
  if (!editor) return null;

  return (
    <div className="w-full bg-white p-4 rounded-lg shadow-lg flex-1 justify-evenly">
        <div className="flex space-x-3 justify-evenly">
            <button onClick={() => editor.chain().focus().toggleBold().run()} className={editor.isActive('bold') ? 'text-highlight' : 'text-header'}>
                <FaBold />
            </button>
            <button onClick={() => editor.chain().focus().toggleItalic().run()} className={editor.isActive('italic') ? 'text-highlight' : 'text-header'}>
                <FaItalic />
            </button>
            <button onClick={() => editor.chain().focus().toggleUnderline().run()} className={editor.isActive('underline') ? 'text-highlight' : 'text-header'}>
                <FaUnderline />
            </button>
            <button onClick={() => editor.chain().focus().toggleStrike().run()} className={editor.isActive('strike') ? 'text-highlight' : 'text-header'}>
                <FaStrikethrough />
            </button>
            <button onClick={() => editor.chain().focus().toggleOrderedList().run()} className={editor.isActive('orderedList') ? 'text-highlight' : 'text-header'}>
                <FaListOl />
            </button>
            <button onClick={() => editor.chain().focus().toggleBulletList().run()} className={editor.isActive('bulletList') ? 'text-highlight' : 'text-header'}>
                <FaListUl />
            </button>
            <button onClick={() => editor.chain().focus().setTextAlign('left').run()} className={editor.isActive({ textAlign: 'left' }) ? 'text-highlight' : 'text-header'}>
                <TfiAlignLeft />
            </button>
            <button onClick={() => editor.chain().focus().setTextAlign('center').run()} className={editor.isActive({ textAlign: 'center' }) ? 'text-highlight' : 'text-header'}>
                <TfiAlignCenter />
            </button>
            <button onClick={() => editor.chain().focus().setTextAlign('right').run()} className={editor.isActive({ textAlign: 'right' }) ? 'text-highlight' : 'text-header'}>
                <TfiAlignRight />
            </button>
            <button onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} className={editor.isActive('heading', { level: 1 }) ? 'text-highlight' : 'text-header'}>
                {/* <FaHeading className="text-lg" /> */}
                <LuHeading1 />
            </button>
            <button onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} className={editor.isActive('heading', { level: 2 }) ? 'text-highlight' : 'text-header'}>
            <LuHeading2 />
            </button>
            <button onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} className={editor.isActive('heading', { level: 3 }) ? 'text-highlight' : 'text-header'}>
                <LuHeading3 />
            </button>
            {/* <button onClick={() => {
                const src = prompt('Enter image URL:');
                if (src) {
                    editor.chain().focus().setResizableImage({ src }).run();
                }
                }} className={editor.isActive('resizableImage') ? 'text-highlight' : 'text-header'}>
                <LuImage />
            </button> */}

        </div>
    </div>
  );
};