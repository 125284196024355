import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { fetchAPI, JournalEntry } from "../utils/api";
import useJournals from "../hooks/useJournals";

interface ListContextProps {
    journals: JournalEntry[];
    addToJournals: (item: JournalEntry) => Promise<void>;
    newJournal: JournalEntry | null;
    isSaving: boolean;
    isFetching: boolean;
    setNewJournal: React.Dispatch<React.SetStateAction<JournalEntry | null>>;
    retriggerFetch: () => void;
}

const ListContext = createContext<ListContextProps | undefined>(undefined);

interface ListProviderProps {
    children: React.ReactNode;
}


const ListProvider: React.FC<ListProviderProps> = ({ children }) => {
    const [newJournal, setNewJournal] = useState<JournalEntry | null>(null); // Local state for new journal entry
    const [journals, setJournals] = useState<JournalEntry[]>([]); // Local state for journals
    const [isSaving, setIsSaving] = useState(false); // Local state for saving status
    const [isFetching, setIsFetching] = useState(false); // Local state for fetching status
    const [fetchTrigger, setFetchTrigger] = useState(0); // Local state to trigger fetching

    const { journals:fetchedJournals, loading } = useJournals(fetchTrigger); // Fetch journals from the API
    const journalQueue = useRef(new JournalQueue()); // Create a new queue instance and store it in a ref

    const fetchListFromAPI = useCallback(async () => {
        try {

            setJournals(fetchedJournals);
            setIsFetching(loading);
        } catch (error) {
            console.error('Error fetching Journals:', error);
        }

    }, [fetchedJournals, loading]);

    

    
    useEffect(() => {
        // call the fetchJournalsFrom API callback function inside useeffect hook
        fetchListFromAPI();
        
    }, [fetchListFromAPI]);

    // Update the local state of journals
    const updateJournalsState  = (journal: JournalEntry) => {
        setJournals((prevList) => {
            const existingIndex = prevList.findIndex((entry) => ((entry.id === journal.id) || (entry.tempId === journal.tempId)));
            if(existingIndex !== -1)
            {
                // Update existing journal entry
                const updateList = [...prevList];
                updateList[existingIndex] = journal;

                return updateList;
            }else {
                // Add new journal Entry
                return [...prevList, journal];
            }
        })
    };

    const retriggerFetch = () => {
        setFetchTrigger((prev) => prev + 1);
    };


    // callback function to add or update journal entry to API
    // add the API request task to the queue

    const addToJournals = useCallback(async (journal: JournalEntry) => {
        return new Promise<void>((resolve, reject) => {
            // Add a task to the queue
            journalQueue.current.enqueue(async () => {
                try {
                    setIsSaving(true); // Set the saving status to true

                    if (journal.id) {
                        // If journal has an ID, it's an update
                        await updateJournalAPI(journal); 
                    } else {
                        // If journal has no ID, it's a new entry
                        const response = await createJournalAPI(journal);
                        // Set the new journal entry in the state
                        setNewJournal(response);
                    }

                    // update the local state after the API call succeeds
                    updateJournalsState(journal);

                    setIsSaving(false); // Set the saving status to false
                    resolve(); // Resolve the promise
                } catch (error) {
                    // Log the error
                    console.error('Error saving journal:', error);
                    reject(error); // Reject the promise
                }
            });
        });
    }, []);


    return (
        <ListContext.Provider value={{ journals, addToJournals, newJournal, isSaving, setNewJournal, isFetching, retriggerFetch }}>
            {children}
        </ListContext.Provider>
    );
};

const useJournalList = (): ListContextProps => {
    const context = useContext(ListContext);
    if(context === undefined)
    {
        throw new Error('useList must be used within a ListProvider');
    }

    return context;
};

export { ListProvider, useJournalList };



class JournalQueue {
    private queue: (() => Promise<void>)[] = [];
    private isProcessing = false;

    // Add a new task to the queue
    enqueue(task: () => Promise<void>) {
        this.queue.push(task);
        if(!this.isProcessing)
        {
            this.processQueue();
        }
    }

    // Process the queue sequentially
    private async processQueue()
    {
       this.isProcessing = true;
       while(this.queue.length > 0)
       {
           const task = this.queue.shift();
           if(task)
           {
               try {
                    await task();
               } catch (error) {
                    console.error('Error processing task:', error);
               }
           }
       }
       this.isProcessing = false;
    }
}

// Update an existing journal entry
// This function makes a PUT request to the API to update an existing journal entry
async function updateJournalAPI(journal: JournalEntry): Promise<JournalEntry | PromiseLike<JournalEntry>> {
    // Create a schema for the journal update
    const journalUpdateSchema = {
        title: journal.title,
        mood: journal.mood,
        isLocked: journal.isLocked,
        tags: journal.tags,
        entries: journal.entries
    };

    // Make the API call
    const response = await fetchAPI(`journals/${journal.id}`, journalUpdateSchema, 'PUT', 'application/json');

    // Check if the API call was successful
    if(response.status)
    {
        // Return the updated journal entry
        return journal;
    }

    // Throw an error if the API call failed
    throw new Error(response.message);
}

// Create a new journal entry
// This function makes a POST request to the API to create a new journal entry
async function createJournalAPI(journal: JournalEntry): Promise<JournalEntry | PromiseLike<JournalEntry>> {
    // Create a schema for the new journal entry
    const journalSchema = {
        title: journal.title,
        mood: journal.mood,
        isLocked: journal.isLocked,
        tags: journal.tags,
        entries: journal.entries,
        journalDate: journal.journalDate
    };

    const response = await fetchAPI(`journals`, journalSchema, 'POST', 'application/json');

    if(response.status)
    {
        console.log('response.data: ', response.data);
        const newJournal = {
            id: response.data.id,
            userId: response.data.userId,
            title: response.data.title,
            mood: response.data.mood,
            isLocked: response.data.isLocked,
            tags: response.data.tags,
            entries: response.data.entries,
            metadata: response.data.metadata,
            journalDate: response.data.journalDate,
            tempId: journal.tempId
        };
        return newJournal;
    }

    throw new Error(response.message);
}
