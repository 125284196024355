import React, { useState } from "react";
import SettingsMenu from "../components/settings/Settings";
import { FaTimes } from "react-icons/fa";
import { useTheme } from "context/ThemeContext";

interface SettingMenuModalProps {
    modelState: (state: boolean) => void;
}

const SettingMenuModal: React.FC<SettingMenuModalProps> = ({ modelState }) => {
    const [menu, setMenu] = useState<string|null>(null);
    const closeModal = () => modelState(false);
    const { isDarkMode } = useTheme();


    return (
        // <div className="flex items-center justify-center h-screen bg-gray-200">
        <div className={`${isDarkMode ? 'dark' : ''} fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 max-md:h-screen dark:bg-setDarkBack dark:text-white`}>
            <div className={`bg-bg-primary max-md:bg-white dark:bg-bgDarkSec max-md:dark:bg-bgDark shadow-lg max-md:w-full md:w-[44rem] md:h-[36rem] max-w-4xl p-6 max-md:h-screen max-md:pl-0 max-md:py-0 ${menu == null ? 'max-md:pr-7' : 'max-md:pr-0'} dark:border-body`}>
                <div className="flex md:justify-between max-md:items-start max-md:gap-x-14 items-center pb-1 mb-3 max-md:hidden">
                    {!isDarkMode ? 
                        <img className="hover:border md:hidden" alt="CaretLeft" src="/images/CaretLeft.svg" onClick={closeModal} />
                        :
                        <img className="hover:border md:hidden" alt="CaretLeft" src="/images/darkCaretLeft.svg" onClick={closeModal} />
                    }
                    
                    
                    <h2 className="text-xl font-semibold text-header dark:text-white">Settings</h2>
                    <button onClick={closeModal} className="max-md:hidden">
                        <FaTimes className="text-header dark:text-white hover:text-red-500" />
                    </button>
                </div>
                <SettingsMenu closeModal={closeModal} generalMenu={setMenu} />
            </div>
        </div>
        // </div>
    );
};

export default SettingMenuModal;