import { CommandProps, Editor, Extension, ReactRenderer } from "@tiptap/react";
import Suggestion, { SuggestionProps } from "@tiptap/suggestion";
import { ChangeEvent, Component } from "react";
import { Instance } from "tippy.js";
import tippy from "tippy.js";

type GetReferenceClientRect = () => DOMRect;

export const CommandPlugins = Extension.create({
    name: "insertMenu",
    addProseMirrorPlugins() {
        return [
            Suggestion<CommandProps>({
                editor: this.editor,
                char: "/",
                command: ({ editor, range, props }) => {
                    props.command({ editor, range, props });
                },
                items({ query, editor }): any {
                    const isEditorEmpty = editor.getText().length <= 1;
                    const user = JSON.parse(localStorage.getItem("user") || "") || {};
                    return (
                        [
                          {
                            title: `Ask ${user.aiName}`,
                            image: "/images/shootingstar.svg",
                            description: "Interact with our AI assistant for personalized journaling support.",
                            editorState: isEditorEmpty,
                            attrs: {
                                "data-test-id": "insert-synth"
                            },
                            command: ({ editor }: { editor: Editor; }) => {
                              const selection = editor.view.state.selection;
                              const from = selection.$from.posAtIndex(0);
                              const to = selection.$from.posAtIndex(1);
                              if(!isEditorEmpty) {
                                editor.chain().focus().deleteRange({ from, to}).insertAIContent().run();
                              }
                            }
                          },
                            {
                                title: "Media",
                                image: "/images/camera.svg",
                                description: "Add media(s) to your journal for a rich and engaging entry.",
                                editorState: false,
                                attrs: {
                                    "data-test-id": "insert-media",
                                },
                                command: ({ editor }: { editor: Editor; }) => {
                                    const selection = editor.view.state.selection;
                                    const from = selection.$from.posAtIndex(0);
                                    const to = selection.$from.posAtIndex(1);
                                    
                                    editor.chain()
                                        .focus()
                                        .deleteRange({from, to})
                                        .setCustomUpload()
                                        .focus()
                                        .run();
                                }
                            },
                            
                        ]
                    )
                    .filter((item) => {
                        return item.title.toLowerCase().startsWith(query.toLowerCase());
                    })
                    .slice(0, 10);
                },
                startOfLine: true,
                allow: ({ state, range, editor }) => {
                    const node = state.selection.$from.node();
                    if (!node) return false;
                    return node.textBetween(0, 1) === "/";
                },
                render() {
                    let component: ReactRenderer<CommandsView, any>, popup: Instance<any>;
                    return {
                        onStart: (props) => {
                                component = new ReactRenderer(CommandsView, {
                                props,
                                editor: props.editor,
                            });
                            popup = tippy(props.editor.options.element, {
                                getReferenceClientRect:
                                props.clientRect as GetReferenceClientRect,
                                content: component.element,
                                showOnCreate: true,
                                interactive: true,
                                trigger: "manual",
                                placement: "bottom-start",
                            });
                        },
                        onUpdate: (props) => {
                            component.updateProps(props);
                            popup.setProps({
                                getReferenceClientRect: props.clientRect,
                            });
                            },
                        onKeyDown: ({ event }) => {
                            if (event.key === "Escape") {
                                popup.hide();
                                return true;
                            }
                            if (component.ref)
                                return component.ref.onKeyDown(event as KeyboardEvent);
                            else return true;
                        },
                        onExit: () => {
                            component.destroy();
                            popup.destroy();
                        },
                    };
                },
            }),
        ];
    },
});

    class CommandsView extends Component<SuggestionProps> {
        state = {
            selectedIndex: null,
        };
    
      componentDidUpdate(oldProps: SuggestionProps) {
        if (this.props.items !== oldProps.items) {
          this.setState({
            selectedIndex: 0,
          });
        }
      }
    
      onKeyDown(event: KeyboardEvent) {
        if (event.key === "ArrowUp") {
          this.upHandler();
          return true;
        }
    
        if (event.key === "ArrowDown") {
          this.downHandler();
          return true;
        }
    
        if (event.key === "Enter") {
          this.enterHandler();
          return true;
        }
    
        return false;
      }
    
      upHandler() {
        this.setState({
          selectedIndex:
            ((this.state.selectedIndex || 0) + this.props.items.length - 1) %
            this.props.items.length,
        });
      }
    
      downHandler() {
        this.setState({
          selectedIndex:
            this.state.selectedIndex === null
              ? 0
              : ((this.state.selectedIndex || 0) + 1) % this.props.items.length,
        });
      }
    
      enterHandler() {
        this.selectItem(this.state.selectedIndex);
      }
    
      selectItem(index: number | null) {
        const item = this.props.items[index || 0];
    
        if (item) {
          this.props.command(item);
        }
      }
    
      render() {
        const { items } = this.props;
        return (
          <div className="insert-menu bg-white shadow-lg rounded-md w-[90%]">
            {items.map((item, index) => {
              return (
                <div
                 
                  className={`${
                    index === this.state.selectedIndex ? "text-highlight" : "text-header"
                  } menu-item flex items-center p-3 gap-x-3 cursor-pointer hover:bg-gray-100 disabled:text-gray-50 disabled:cursor-not-allowed ${item.editorState ? 'pointer-events-none text-gray-50':''}`}
                  {...item.attrs}
                  key={index}
                  onClick={() => this.selectItem(index)}
                >
                    <img
                        src={item.image}
                        alt=""
                        className="menu-item-icon w-6 h-6 relative -top-4"
                    />
                    <div className="menu-item-content">
                        <div className={`menu-item-title font-medium text-sm ${item.editorState ? 'text-gray-300' : 'text-gray-900'} mb-1`}>
                            {item.element || item.title}
                        </div>
                        <div className={`menu-item-description text-xs ${item.editorState ? 'text-gray-200' : 'text-body'}  font-normal`}>
                            {item.description}
                        </div>
                    </div>
                    <input type="file" style={{display: 'none' }} 
            />
                </div>
              );
            })}
          </div>
        );
      }
    }

