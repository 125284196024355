import { useEffect, useState, useMemo } from 'react';

const Typewriter = () => {
  const dataText = useMemo(() => ["Journal Your Thoughts on the Go."], []);
  const [textIndex, setTextIndex] = useState(0);
  const [currentText, setCurrentText] = useState('');
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    // Function to handle the typewriter effect
    const typeWriter = () => {
      if (charIndex < dataText[textIndex].length) {
        setCurrentText((prev) => prev + dataText[textIndex].charAt(charIndex));
        setCharIndex(charIndex + 1);
      } else {
        setTimeout(() => {
          setCharIndex(0);
          setCurrentText('');
          setTextIndex((prevIndex) => (prevIndex + 1) % dataText.length);
        }, 700); // Delay before starting next word
      }
    };

    if (charIndex < dataText[textIndex].length) {
      const timeoutId = setTimeout(typeWriter, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [charIndex, textIndex, dataText]);

  return (
    <h1 className="max-md:text-2xl z-10 text-5xl font-bold text-black dark:text-white" style={{textShadow: "0 1px 3px #0003"}}>
      {currentText}
      <span aria-hidden="true"></span>
    </h1>
  );
};

export default Typewriter;
