import { mergeAttributes, Node } from '@tiptap/core';
import { fetchAPI } from 'utils/api';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    aiSynthWriter: {
      insertAIContent: () => ReturnType;
    };
  }
}

export const SynthWriter = Node.create({
  name: 'aiSynthWriter',

  group: 'block',

  draggable: true,

  addOptions() {
    return {
      authorId: undefined,
      authorName: undefined,
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
      apiEndpoint: '', // Your API endpoint here
    };
  },

  addAttributes() {
    return {
      // Add any node-specific attributes here
    };
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      insertAIContent:
        () =>
        ({ chain, state, editor, dispatch }) => {
          const { from } = state.selection;
          const user = JSON.parse(localStorage.getItem("user") || "{}") || {};

          // content up to focus position
          const bodyContent = editor.getText();

        //   // Generate a unique class or ID for the skeleton
        //   const skeletonClass = `loading-skeleton-${from}`;
        // //   const skeletonClass = 'loading-skeleton';
        //   const skeleton = `<div class="react-component-node ${skeletonClass}"></div>`;
        // // const skeleton = `<p class="loading-skeleton">Loading...</p>`;
        //   // Insert the skeleton
        //   chain()
        //     .focus()
        //     .insertContentAt(from, skeleton)
        //     .run();


        //   // Handle the async operation but return true immediately
        //   (async () => {
        //     try {
        //     //   Make the API call
        //       const bodyJson = { content: bodyContent };
        //       const response = await fetchAPI(this.options.apiEndpoint, bodyJson, 'POST', 'application/json');
              
        //       let content = response.data || `Failed to generate content...`;
        //       if(!response.status)
        //         content = response.message;
        //       // content = response.data || `Failed to generate content...`;

        //       content = `<p><span data-type="emoji" data-name="shootingstar"></span> <strong data-type='ai_name'>${user.aiName}</strong></p>${content}`;
        //       // remove all empty paragraphs
        //       content = content.replace(/<p><\/p>/g, '');
        //       content = content.replace(/<p>/g, '<p data-type="ai">');
        //       // Replace the skeleton with the AI-generated content
        //         editor.chain()
        //             .focus()
        //             .deleteNode('paragraph') // delete the skeleton
        //             .setTextSelection(from)
        //             .setHorizontalRule()
        //             .insertContent(content)
        //             .setHorizontalRule()
        //             .deleteRange({ from: from - 1, to: from })
        //             .run();

        //     } catch (error) {
        //       console.error('Error during API call:', error);
        //       // Replace the skeleton with an error message
        //       chain()
        //         .focus()
        //         .deleteNode('paragraph') // delete the skeleton
        //         .setTextSelection(from)
        //         .insertContentAt(from, `<p class="error">Error loading content</p>`)
        //         .deleteRange({ from: from - 1, to: from })
        //         .run();
        //     }
        //   })();
        // Trigger a callback instead of inserting content immediately
        if (this.options.onAskAI) {
          this.options.onAskAI({
            from, 
            bodyContent, 
            editor,
            chain,
            user,
            dispatch,
            apiEndpoint: this.options.apiEndpoint
          });
        }

          // Return true to indicate the command was executed
          return true;
        },
    };
  },
});

export default SynthWriter;
