import React from "react";
import Login from "../components/auth/Login";
import { ToastProvider } from "../context/ToastContext";

const LoginPage: React.FC = () => {
    return (
        <ToastProvider>
            <Login />
        </ToastProvider>
    );
};

export default LoginPage;