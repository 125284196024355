import { useTheme } from "context/ThemeContext";
import React, { useEffect, useRef, useState } from "react";
import { IoCheckmark } from "react-icons/io5";

interface ThemeSelectorProps {
    handleDropdownChange?: (field: string, value: string) => void;
    currentTheme: string;
}



export const ThemeSelector: React.FC<ThemeSelectorProps> = ({ handleDropdownChange, currentTheme }) => {
  // console.log('Journal:', journal.tags.map((tag: string) => JSON.parse(tag)));
  const [selectedTheme, setSelectedTheme] = useState<string>(currentTheme || 'System');
  const [dropdownOpen, setDropdownOpen] = useState(false);
//   const [availableThemes, setAvailableThemes] = useState<string[]>(['System', 'Light Mode', 'Dark Mode']);
  const availableThemes = ['System', 'Light Mode', 'Dark Mode'];
  const {isDarkMode} = useTheme();

  const toggleTheme = (theme: string) => {
        setSelectedTheme(theme);
        handleDropdownChange && handleDropdownChange('theme', theme);
        setDropdownOpen(false);
  };

    useEffect(() => {
        if (currentTheme) {
            setSelectedTheme(currentTheme);
        }
    }, [currentTheme]);


  // generate click outside handler to close dropdown
  const handleRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (handleRef.current && !handleRef.current?.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={handleRef}>
      <div onClick={() => setDropdownOpen(!dropdownOpen)} className='flex items-center gap-x-3'>
        
        <span className="text-body">{selectedTheme}</span>
        { dropdownOpen ?
            <img src={`/images/settings/${isDarkMode ? 'darkCaretUp.svg':'CaretUp.svg'}`} alt="" onClick={() => setDropdownOpen(!dropdownOpen)} />
            :
            <img src={`/images/settings/${isDarkMode ? 'darkCaretDown.svg':'CaretDown.svg'}`} alt="" onClick={() => setDropdownOpen(!dropdownOpen)} />
        }
        

      </div>
      {dropdownOpen && (
        <div className="absolute top-full right-0 w-[150px] overflow-y-auto bg-white dark:bg-bgDark border border-gray-placeholder rounded shadow-lg mt-1 z-10">
          {availableThemes.map((theme, index) => (
            <div
              key={`${theme}-${index}`}
              className={`cursor-pointer px-4 py-2 hover:bg-gray-100 dark:hover:bg-body bg-white dark:bg-bgDark flex items-center justify-between`}
              onClick={() => toggleTheme(theme)}
            >
                {theme}

                {selectedTheme === theme &&
                    <span className={`inline-block w-3 h-3 ml-2 rounded-full`}><IoCheckmark className="text-highlight" /></span>
                }
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

