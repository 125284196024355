import { useState, useEffect } from "react";
import { BaseResponse, fetchAPI, TokenEntry } from "../utils/api";

export interface UpdateProfile {
    name: string;
    profileUrl: string;
}

export interface apiProps {
    data: any | UpdateProfile | null | undefined | TokenEntry;
    method: string;
    endpoint: string;
    contentType?: string;
}

export const useApi = (children: apiProps) => {
    const [baseResponse, setBaseResponse] = useState<BaseResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
  
    useEffect(() => {
        if(!children.data) return;
    
        const setApiResponse = async (data: apiProps) => {
          try {
            setLoading(true);
            // console.log("Data:", data.data);
            const response = await fetchAPI(data.endpoint, data.data, data.method);
            // console.log("API Fetch response:", response);
            
            setBaseResponse(response);
    
          } catch (error) {
            console.error("Error uploading:", error);
          } finally {
            setLoading(false);
          }
        };
    
        setApiResponse(children);
        
       
      }, [children]);
    
    return { loading, baseResponse };
  
};