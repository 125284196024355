import { motion } from "framer-motion";
import React, { useState } from "react";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";


interface NavbarProps {
    faqRef: React.RefObject<HTMLDivElement>;
}

const FaqFeature: React.FC<NavbarProps> = ({ faqRef }) => {

    const [openFaqIndex, setOpenFaqIndex] = useState<number | null>(null);

    const toggleFaq = (index: number) => {
        if (openFaqIndex === index) {
            setOpenFaqIndex(null);
        } else {
            setOpenFaqIndex(index);
        }
    };

    const navigate = useNavigate();

    const handleGetStarted = () => {
        navigate("/register");
    }

    const faqs = [
        {
            id: 1,
            question: "What platforms is toice available on?",
            answer:
                "Toice is accessible on web browsers across desktops, smartphones, and tablets, making it easy to journal from any device without needing a dedicated app.",
        },
        {
            id: 2,
            question: "Is my data secured in the journaling app?",
            answer:
                "Yes, we take data security seriously. Our app uses end-to-end encryption to protect your journal entries from unauthorized access.",
        },
        {
            id: 3,
            question: "Can I export my journal entries?",
            answer:
                "Yes, users can export their journal entries from the app. Toice allows users to download their entries as PDF file which will be sent to their email address.",
        },
        {
            id: 4,
            question: "What happens if I delete my account?",
            answer:
                "If you delete your account, all your journal entries will be permanently deleted from our servers. We recommend exporting your entries before deleting your account.",
        },
        {
            id: 5,
            question: "How does Toice protect my privacy while using AI features?",
            answer:
            "Toice follows the privacy-first concept, ensuring that no AI features are utilized to collect or keep user data. The AI functions are intended to improve the journaling experience while preserving user privacy.",
        }
    ];

    return (
        <div className="w-full py-16" id="#faqs" ref={faqRef}>
            <div className="container mx-auto px-4">

                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className="text-center mb-12 max-md:w-full mx-auto"
                >
                    <h2 className="text-3xl font-bold">Get the <span className="text-primary">answers</span> you are looking for <br className="max-md:hidden" /> about Toice</h2>
                    <p className="text-black pt-3 ">Find detailed answers to frequently asked questions about Toice and its features.</p>
                </motion.div>

                {/* Collapsable card styling */}
                <div className="flex flex-col items-center">
                    {faqs.map((faq, index) => (
                        <div key={index} className={`md:w-[80%] w-full bg-white py-6 px-2 mb-5 ${faqs.length === (index + 1) ? 'border-b-0' : 'border-b-2'} border-gray-300`}>
                            <button className="flex justify-between items-center w-full"
                                onClick={() => toggleFaq(index)}>
                                <h3 className="text-xl font-semibold max-md:text-left">{faq.question}</h3>
                                {openFaqIndex === index ? <FiMinusCircle className="dark:text-white w-6 h-6" /> : <FiPlusCircle className="w-6 h-6 dark:text-white" />}
                            </button>
                            <div className={`content ${openFaqIndex === index ? 'block' : 'hidden'} mr-10`}>
                                <p className="text-black mt-4 text-xl font-medium text-justify leading-7">{faq.answer}</p>
                            </div>
                        </div>
                    ))}

                </div>

            </div>


            <div
                className="w-full h-[600px] mb-5 flex flex-col mt-10 items-center justify-center object-cover"
                style={{
                    backgroundImage: "url(/images/feat/jr.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <div className="content md:w-3/4 text-white text-center flex flex-col gap-y-4">
                    <h2 className="md:text-5xl text-3xl font-bold">
                        Is your story unfolding right?
                    </h2>
                    <p className="text-xl">
                        Join countless users who use Toice.
                    </p>
                    <p className="text-lg">
                        Document your thoughts, dreams, and experiences in a journal that genuinely represents you. Begin your writing journey now on Toice, accessible on your mobile, tablet, or desktop browser.
                    </p>
                    <p className="mt-10">
                        <button
                            onClick={handleGetStarted}
                            className="w-72 font-semibold text-xl bg-primary text-white p-4 rounded-lg"
                        >
                            Start writing now
                        </button>
                    </p>
                </div>
            </div>

        </div>
    );

};

export default FaqFeature;