/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from "context/ThemeContext";
import React, { useEffect, useRef, useState } from "react";

interface AiNameSelectorProps {
    handleDropdownChange?: (field: string, value: string) => void;
    currentAiName: string;
}



export const AiNameSelector: React.FC<AiNameSelectorProps> = ({ handleDropdownChange, currentAiName }) => {
  const [selectedAiName, setSelectedAiName] = useState<string>(currentAiName);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { isDarkMode } = useTheme();

  const toggleAiPersonal = (name: string) => {
    setSelectedAiName(name);
  };

    useEffect(() => {
      setSelectedAiName(currentAiName);
    }, [currentAiName]);

    useEffect(() => {
      if (currentAiName !== selectedAiName) {
        handleDropdownChange && handleDropdownChange('aiName', selectedAiName);
      }
    },[selectedAiName]);


  // click outside handler to close dropdown
  const handleRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (handleRef.current && !handleRef.current?.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={handleRef}>
      <div onClick={() => setDropdownOpen(!dropdownOpen)} className='flex items-center gap-x-3'>
        
        <span className="text-body">{selectedAiName}</span>
        { dropdownOpen ?
            <img src={`/images/settings/${isDarkMode ? 'darkCaretUp.svg':'CaretUp.svg'}`} alt="" onClick={() => setDropdownOpen(!dropdownOpen)} />
            :
            <img src={`/images/settings/${isDarkMode ? 'darkCaretDown.svg':'CaretDown.svg'}`} alt="" onClick={() => setDropdownOpen(!dropdownOpen)} />
        }

      </div>
      {dropdownOpen && (
        <div className="absolute top-full right-0 w-40  bg-white dark:bg-bgDark border border-gray-placeholder rounded shadow-lg mt-1 z-10">
          
            <div
              key={`${selectedAiName}-1`}
              className={`cursor-pointer px-4 py-2 bg-white dark:bg-bgDark`}
            >
                <input type="text" placeholder="Synth" autoFocus={true} className="dark:text-white dark:bg-bgDark placeholder:text-gray-placeholder outline-none border-none w-full" maxLength={18} value={selectedAiName} onChange={(e) => toggleAiPersonal(e.target.value)} />
            </div>
          
        </div>
      )}
    </div>
  );
};

