import React, { useState } from 'react';
import TopBar from '../components/TopBar';
import { ListProvider } from '../context/ListContext';
import SettingMenuModal from './SettingMenuModal';
import JournalApp from 'components/journal/JournalApp';
import { AuthProvider } from 'context/AuthContext';
import { useTheme } from 'context/ThemeContext';

const EditorPage: React.FC = () => {
  const [isSideBarVisible, setSideBarVisible] = useState(false);
  // const [journal, setJournal] = useState<JournalEntry>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editorOpen, setEditorOpen] = useState(false);

  const { isDarkMode } = useTheme();

  const openModal = (state: boolean) => {
    setIsModalOpen(true);
  };

  const toggleSidebar = () => {
    setSideBarVisible(!isSideBarVisible);
  };

  // const toggleJournal = (journal: JournalEntry) => {
  //   setJournal(journal);
  //   // console.log(journal.id);
  //   // console.log(journal);
  // };


  return (
    <ListProvider>
      <AuthProvider>
      <div className={`${isDarkMode ? 'dark' : ''} flex max-md:h-screen overflow-hidden w-full md:overflow-hidden md:h-screen max-md:px-2 dark:bg-bgDark`}>
        {/* {isSideBarVisible  && <Sidebar journal={toggleJournal} />} */}
        <div className="flex-1 flex flex-col journal-doc">
          <TopBar toggleSidebar={toggleSidebar} modalState={openModal} editorOpen={editorOpen} />
          {/* <MainContent journal={journal} /> */}
          <JournalApp toggleFullScreen={isSideBarVisible} editorOpen={editorOpen} setEditorOpen={setEditorOpen}  />
        </div>
      </div>
      {isModalOpen && <SettingMenuModal modelState={setIsModalOpen} /> }
      </AuthProvider>
    </ListProvider>
  );
};

export default EditorPage;
