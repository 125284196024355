
import { useTheme } from "context/ThemeContext";
import React, { useEffect, useRef, useState } from "react";
import { IoCheckmark } from "react-icons/io5";

interface AiPersonalSelectorProps {
    handleDropdownChange?: (field: string, value: string) => void;
    currentAiPersonal: string;
}



export const AiPersonalSelector: React.FC<AiPersonalSelectorProps> = ({ handleDropdownChange, currentAiPersonal }) => {
  const [selectedAiPersonal, setSelectedAiPersonal] = useState<string>(currentAiPersonal);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const availableAiPersonalOptions = ['Formal', 'Casual', 'Friendly'];
  const {isDarkMode} = useTheme();

  const toggleAiPersonal = (personality: string) => {
        setSelectedAiPersonal(personality);
        handleDropdownChange && handleDropdownChange('aiPersonality', personality);
        setDropdownOpen(false);
  };

    useEffect(() => {
        if (currentAiPersonal) {
            setSelectedAiPersonal(currentAiPersonal);
        }
    }, [currentAiPersonal]);


  // generate click outside handler to close dropdown
  const handleRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (handleRef.current && !handleRef.current?.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={handleRef}>
      <div onClick={() => setDropdownOpen(!dropdownOpen)} className='flex items-center gap-x-3'>
        
        <span className="text-body">{selectedAiPersonal}</span>
        { dropdownOpen ?
            <img src={`/images/settings/${isDarkMode ? 'darkCaretUp.svg':'CaretUp.svg'}`} alt="" onClick={() => setDropdownOpen(!dropdownOpen)} />
            :
            <img src={`/images/settings/${isDarkMode ? 'darkCaretDown.svg':'CaretDown.svg'}`} alt="" onClick={() => setDropdownOpen(!dropdownOpen)} />
        }

      </div>
      {dropdownOpen && (
        <div className="absolute top-full right-0 w-[150px] overflow-y-auto bg-white dark:bg-bgDark border border-gray-placeholder rounded shadow-lg mt-1 z-10">
          {availableAiPersonalOptions.map((personality, index) => (
            <div
              key={`${personality}-${index}`}
              className={`cursor-pointer px-4 py-2 hover:bg-gray-100 dark:hover:bg-body dark:bg-bgDark bg-white flex items-center justify-between`}
              onClick={() => toggleAiPersonal(personality)}
            >
                {personality}

                {selectedAiPersonal === personality &&
                    <span className={`inline-block w-3 h-3 ml-2 rounded-full`}><IoCheckmark className="text-highlight" /></span>
                }
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

