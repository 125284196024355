import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

// Create the Theme Context
const ThemeContext = createContext({
  isDarkMode: true,
  toggleTheme: (value: boolean) => {},
});

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  
  const [isDarkMode, setIsDarkMode] = useState(false); // Default to light mode

  const toggleTheme = (value: boolean) => {
    setIsDarkMode(value);
  };

  useEffect(() => {
    const themePrefer = localStorage.getItem('theme-preference');
    if(themePrefer)
    {
      if(themePrefer === 'Dark Mode')
        setIsDarkMode(true);
      else
        setIsDarkMode(false);
    }else {
      setIsDarkMode(false);
    }

}, []);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Custom hook to use the Theme Context
export const useTheme = () => {
  return useContext(ThemeContext);
};
