import { useTags } from "hooks/useApp";
import React, { useEffect, useRef, useState } from "react";
import { JournalEntry } from "utils/api";
import { Tag } from "utils/helper";

interface TagSelectorProps {
  onTagChange?: (value: any) => void;
  journal: JournalEntry;
}



const TagSelector: React.FC<TagSelectorProps> = ({ onTagChange, journal }) => {
  const [selectedTags, setSelectedTags] = useState<Tag[]>(journal.tags.map((tag: string) => JSON.parse(tag)) );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [availableTags, setAvailableTags] = useState<Tag[]>([]);
  const { tags } = useTags();

  const toggleTag = (tag: Tag) => {
    if (selectedTags.find(t => t.label === tag.label)) {
      const newTags = selectedTags.filter(t => t.label !== tag.label);
      onTagChange &&  onTagChange(newTags);
      setSelectedTags(newTags);

    } else {
      const newTags = [...selectedTags, tag];
      onTagChange && onTagChange(newTags);
      setSelectedTags(newTags);
    }

    
  };

  useEffect(() => {
    if (journal) {
      setSelectedTags(journal.tags.map((tag: string) => JSON.parse(tag)));
      // console.log('Journal tags:', selectedTags);
    }
  }, [journal]);

  useEffect(() => {
    if (tags) {
      // console.log('Available tags:', tags);
      setAvailableTags(tags);
    }

  },[tags]);



  // generate click outside handler to close dropdown
  const handleRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (handleRef.current && !handleRef.current?.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-[80%]" ref={handleRef}>
      <div onClick={() => setDropdownOpen(!dropdownOpen)} className={`flex items-center flex-wrap border-gray-300 rounded px-2 py-0.5 ${selectedTags.length === 0 ? 'h-10' : ''}`}>
        {selectedTags.length === 0 && <span className="text-gray-400">Empty</span>}
        {selectedTags.map((tag, index) => (
          <div onClick={() => toggleTag(tag)} key={`${tag.label}-${index}`} className={`flex items-center bg-${tag.color} text-header px-1 py-0.5 rounded mr-1 `}>
            {tag.label}
            { dropdownOpen &&
            <button
              className="ml-1 text-xs text-header hover:text-gray-200"
              onClick={() => toggleTag(tag)}
            >
              &times;
            </button> }
          </div>
        ))}
        
      </div>
      {dropdownOpen && (
        <div className="absolute top-full left-0 w-[50%] h-40 overflow-y-auto bg-white border border-gray-300 rounded shadow-lg mt-1 z-10">
          {availableTags.map((tag, index) => (
            <div
              key={`${tag.label}-${index}`}
              className={`cursor-pointer px-4 py-2 hover:bg-gray-200 ${selectedTags.find(t => t.label === tag.label) ? 'bg-gray-300' : ''}`}
              onClick={() => toggleTag(tag)}
            >

              <span className={`inline-block w-3 h-3 mr-2 rounded-full bg-${tag.color}`}></span>
              {tag.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TagSelector;
