import { useState, useEffect } from "react";
import { UploadMedia, BaseResponse, UploadFormDataAPI } from "../utils/api";


export const useMediaUpload = (data: UploadMedia | null) => {
    const [baseResponse, setBaseResponse] = useState<BaseResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
  
    useEffect(() => {
        if(!data) return;
    
        const setMediaResponse = async (data: UploadMedia) => {
          try {
            setLoading(true);
    
            const response = await UploadFormDataAPI('storages/upload', data.file);
            // console.log("Image Upload response:", response);
            
            setBaseResponse(response);
    
          } catch (error) {
            console.error("Error uploading:", error);
          } finally {
            setLoading(false);
          }
        };
    
        setMediaResponse(data);
        
       
      }, [data]);
    
    return { loading, baseResponse };
  
};