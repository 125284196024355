import { Link, useNavigate } from 'react-router-dom';

interface FootbarProps {
  featureRef: React.RefObject<HTMLDivElement>;
  faqRef: React.RefObject<HTMLDivElement>;
}

const Footer: React.FC<FootbarProps> = ({ featureRef, faqRef }) => {

  const navigate = useNavigate();
  return (
    <footer className="bg-primary dark:bg-gray-800 text-white dark:text-gray-100 py-16 pb-8 md:px-32 px-10">
      <div className="container mx-auto md:px-4 flex flex-col md:flex-row justify-between items-start text-center md:text-left">
        <h2 className="text-2xl font-semibold mb-4">Subscribe to our newsletter to <br className='max-md:hidden'/>get our latest updates</h2>
        <form className="flex flex-col md:flex-row items-center mx-auto w-full md:w-2/5">
          <input
            type="email"
            placeholder="Email address"
            className="px-4 py-2 rounded-md mb-4 md:mb-0 md:mr-4 dark:bg-gray-700 dark:text-gray-100 w-full"
          />
          <button type="submit" className="w-40 px-4 py-2 bg-white text-primary rounded-sm font-semibold">
            Subscribe
          </button>
        </form>
      </div>
      <div className="container w-full mt-8 mx-auto px-4 flex flex-col md:flex-row md:justify-evenly gap-40 text-left">
        {/* Newsletter subscription form */}
        <div className="mb-8 md:mb-0 max-md:hidden">
          <div className="brand flex flex-col items-start mt-4" onClick={() => navigate('/')}>
            <img src="/images/darkLogo.png" alt="Toice Logo" className="w-16 mb-2" />
            <p>Journal Your Thoughts on the Go.</p>
          </div>
        </div>
        <div className='mx-auto md:px-4 flex flex-row space-x-10 md:space-x-20 '>
          {/* Company information links */}
          <div className="mb-8 md:mb-0">
            <h3 className="text-lg font-semibold mb-4">Company</h3>
            <ul>
              <li><Link to="/" className="">Home</Link></li>
              <li><a href="#features" className="" onClick={() => (featureRef.current?.scrollIntoView({behavior: 'smooth'}))}>Features</a></li>
              <li><a href="#faqs" className="" onClick={() => (faqRef.current?.scrollIntoView({behavior: 'smooth'}))}>FAQs</a></li>
            </ul>
          </div>
          {/* Contact information */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact us</h3>
            <ul>
              <li><a href="mailto:info@emusoft.co" className="">info@emusoft.co</a></li>
              {/* <li><a href="tel:+11234567890" className="">(+1) 123-456-7890</a></li> */}
              <li><a href="https://facebook.com/toice" className="">Facebook</a></li>
              <li><a href="#instagram" className="">Instagram</a></li>
              <li><a href="#linkedIn" className="">LinkedIn</a></li>
              <li><a href="https://x.com/toice" className="">Twitter</a></li>
            </ul>
          </div>
        </div>

        
      </div>
      <div className="footer-bottom text-center mt-8">
        <p>© Copyright {new Date().getFullYear()}. All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
