import MyLoadingComponent from "components/journal/AiLoading";
import { getCurrentUser } from "utils/api";
import { Panel } from "./ui/Panel";
import { PanelHeadline } from "./components/ui/Panel";
import { Button } from "./components/ui/Button/Button";
import { Icon } from "./components/ui/Icon";

interface AIDialogProps {
  aiContent: string;
  onAccept: () => void;
  onRegenerate: () => void;
  onDecline: () => void;
  loading: boolean;
}

const AIDialog: React.FC<AIDialogProps> = ({ aiContent, onAccept, onRegenerate, onDecline, loading}) => {
  let aiName = getCurrentUser()?.aiName || 'Synth';
  return (
    
    <Panel noShadow className="w-full">
        <div className="flex flex-col p-1">
          {loading ? <MyLoadingComponent />
          : (
            <>
              <PanelHeadline>
                <div className="flex items-center gap-1 font-bold">
                  <img src="/images/shootingstar.svg" alt="Ai" /> 
                  {aiName}
                  <span>Insight</span>
                </div>
                
              </PanelHeadline>
              <div
                className="bg-white dark:bg-black border-l-4 border-neutral-100 dark:border-neutral-700 text-black dark:text-white text-base max-h-[14rem] mb-4 ml-2.5 overflow-y-auto px-4 relative"
                dangerouslySetInnerHTML={{ __html: aiContent }}
              />
            </>
          )}
          <div className="flex flex-row items-center justify-between gap-1">
            
            <div className="flex justify-between w-auto gap-1">
              
                <Button
                  variant="ghost"
                  className="disabled:text-red-300 disabled:hover:text-red-300 text-red-600 hover:bg-red-600/10 hover:text-red-600"
                  onClick={onDecline}
                >
                  <Icon name="Trash" />
                  Discard
                </Button>
              
              
                <Button variant="ghost" onClick={onAccept} disabled={loading}>
                  <Icon name="Check" />
                  Insert
                </Button>
              
              
                <Button variant="ghost" onClick={onRegenerate} disabled={loading}>
                  <Icon name="Repeat" />
                  Regenerate
                </Button>
            </div>
          </div>
        </div>
      </Panel>
  );
};

  
export default AIDialog;