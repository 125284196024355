import React, {createContext, useState, ReactNode, useContext, useCallback, Key} from "react";
import Toast from "../components/Toast";

interface ToastContextProps {
    showToast: (message: string, type: "success" | "error" | "warning" | "info") => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

interface ToastProviderProps {
    children: ReactNode;
}

const ToastProvider: React.FC<ToastProviderProps> = ({children}) => {
    const [toasts, setToasts] = useState<{id: Key, message: string, type: "success" | "error" | "warning" | "info"} []>([]);

    const showToast = useCallback((message: string, type: "success" | "error" | "warning" | "info") => {
        const id = String(Math.random().toString(36)).substring(2, 9);
        setToasts((prevToasts) => [...prevToasts, {id, message, type}]);
    }, []);

    const removeToast = (id: string) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };

    return (
        <ToastContext.Provider value={{showToast}}>
            {children}
            <div className="toast-container">
                {toasts.map((toast) => (
                    <Toast key={toast.id} message={toast.message} type={toast.type} onClose={() => removeToast(String(toast.id))} />
                ))}
            </div>
        </ToastContext.Provider>
    );
};

const useToast = (): ToastContextProps => {
    const context = useContext(ToastContext);

    if(context === undefined) {
        throw new Error("useToast must be used within a ToastProvider");
    }

    return context;
};

export {ToastProvider, useToast};