import { useEffect, useState } from "react";
import { BaseResponse, fetchAPI, RegistrationEntry, TokenEntry } from "../utils/api";

export const useRegister = (regData: RegistrationEntry | null) => {
    const [baseResponse, setBaseResponse] = useState<BaseResponse | null>(null);
    // const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if(!regData) return;
    
        const setRegResponse = async (regData: RegistrationEntry) => {
          try {
            // setLoading(true);
    
            const response = await fetchAPI('auth/signup', regData);
            // console.log("Register response:", response);
            
            setBaseResponse(response);

            if(response && response.status){
              localStorage.setItem("reg:id", response.data.userId);
              localStorage.setItem("reg:email", regData.email);
            }
    
          } catch (error) {
            console.error("Error registering user:", error);
          } finally {
          }
        };
    
        setRegResponse(regData);
        
       
      }, [regData]);
    
      return { baseResponse };
  
};

export const useVerify = (verData: TokenEntry | null) => {
  const [baseResponse, setBaseResponse] = useState<BaseResponse | null>(null);
  // const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
      if(!verData) return;
  
      const setRegResponse = async (verData: TokenEntry) => {
        try {
          // setLoading(true);
  
          const response = await fetchAPI(`auth/auth-token/${verData.userId}/${verData.token}`, '', 'GET');
          // console.log("Token Verification response:", response);
          
          setBaseResponse(response);

          if(response && response.status){
            localStorage.removeItem("reg:id");
            localStorage.removeItem("reg:email");
          }
  
        } catch (error) {
          console.error("Error verifying token:", error);
        } finally {
          // setLoading(false);
        }
      };
  
        setRegResponse(verData);
      
     
    }, [verData]);
  
    return { baseResponse };

};

export const useVerifyFPassword = (verData: TokenEntry | null) => {
  const [baseResponse, setBaseResponse] = useState<BaseResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
      if(!verData) return;
  
      const setRegResponse = async (verData: TokenEntry) => {
        try {
          setLoading(true);
  
          const response = await fetchAPI(`auth/reset-token/${verData.userId}/${verData.token}`, '', 'GET');
          // console.log("Token Verification response:", response);
          
          setBaseResponse(response);

          if(response && response.status){
            localStorage.removeItem("reg:id");
            localStorage.setItem("reg:token", verData.token);
          }
  
        } catch (error) {
          console.error("Error verifying token:", error);
        } finally {
          setLoading(false);
        }
      };
  
        setRegResponse(verData);
      
     
    }, [verData]);
  
    return { loading, baseResponse };

};