import React, { useEffect, useState } from "react";
import { ThemeSelector } from "../personalize/ThemeSelector";
import { AiRespSelector } from "../personalize/AiRespSelector";
import { AiPersonalSelector } from "../personalize/AiPersonalSelector";
import { AiNameSelector } from "../personalize/AiNameSelector";
import { apiProps, useApi } from "hooks/useApi";
import { AiPrefUpdate, NotifyErrorOptions } from "utils/helper";
import { getUserProfile } from "utils/api";
import { toast } from "react-toastify";
import { useTheme } from "context/ThemeContext";
import { Spinner } from "../../../tiptap/ui/Spinner";

interface PersonalContentProps {
  modelState: boolean;
  setModelState: (state: boolean) => void;
}

const PersonalizationContent: React.FC<PersonalContentProps> = ({modelState, setModelState}) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const themePreference = localStorage.getItem('theme-preference');
    const [theme, setTheme] = useState(themePreference || 'System');
    const [aiName, setAiName] = useState(user.aiName || 'Synth');
    const [aiPersonality, setAiPersonality] = useState(user.aiPersonality || 'Friendly');
    const [aiResponseLength, setAiResponseLength] = useState(user.aiLength || 'Medium');
    const [ updateTrigger, setUpdateTrigger ] = useState<apiProps>({ data: null, method: 'POST', endpoint:''});
    const { loading, baseResponse } = useApi(updateTrigger)

    const { isDarkMode, toggleTheme } = useTheme();
    

    const handleDropdownChange = (field:string, value:string) => {
        switch (field) {
          case 'theme':
            setTheme(value);
            break;
          case 'aiName':
            setAiName(value);
            break;
          case 'aiPersonality':
            setAiPersonality(value);
            break;
          case 'aiResponseLength':
            setAiResponseLength(value);
            break;
          default:
            break;
        }
      };

    // Handle Revert changes
    const handleRevertChanges = () => {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
        setTheme('System');
        setAiName(user.aiName || 'Synth');
        setAiPersonality(user.aiPersonality || 'Friendly');
        setAiResponseLength(user.aiLength  ||'Medium');

    }
    // Handle Save Changes
    const handleSaveChanges = () => {
      
      // communcate with the server to save the changes
      const data: AiPrefUpdate = {aiName: aiName, aiPersonality: aiPersonality, aiLength: aiResponseLength};
    
      setUpdateTrigger({data: data, method: 'PUT', endpoint: 'ai/preference'});
    }

    useEffect(() => {
      localStorage.setItem('theme-preference', theme.trim());

      switch (theme.trim()) {
        case "Dark Mode":
          toggleTheme(true);
          break;

        default:
          toggleTheme(false);
          break;
      }
    }, [theme, toggleTheme])

    useEffect(() => {
      async function fetchData() {
        if(baseResponse){
          if(baseResponse.status) {
            // toast.success('Changes saved successfully', NotifyOptions);
            await getUserProfile()
            // console.log('Update response:', baseResponse);
          }else {
            toast.error(baseResponse.message, NotifyErrorOptions);
          }
        }
      }
      fetchData();
    },[baseResponse]);

  return (
    <div className={`${modelState ? 'max-md:visible':'max-md:hidden'} max-md:px-4`}>
      <div className="flex max-md:items-start max-md:justify-between md:border-b-2 border-gray-200">
        {!isDarkMode ? 
          <img className=" md:hidden" src="/images/CaretLeft.svg" alt="" onClick={() => setModelState(false)} />
            :
          <img className=" md:hidden" src="/images/darkCaretLeft.svg" alt="" onClick={() => setModelState(false)} />
        }
        <h1 className="text-xl font-semibold text-header pb-4 dark:text-white">Personalization</h1>
        <div></div>
      </div>

      <div className="mt-2">
        <ul className="set-options-list max-md:mb-10">
            <li className="set-option-item ">
                <label htmlFor="theme">Theme</label>
                <ThemeSelector handleDropdownChange={handleDropdownChange} currentTheme={theme} />
            </li>
            <li className="set-option-item">
                <label htmlFor="aiName">Change AI name</label>
                <AiNameSelector handleDropdownChange={handleDropdownChange} currentAiName={aiName} />
            </li>
            <li className="set-option-item">
                <label htmlFor="aiPersonality">AI Personality</label>
                <AiPersonalSelector handleDropdownChange={handleDropdownChange} currentAiPersonal={aiPersonality} />
            </li>
            <li className="set-option-item max-md:border-b-0">
                <label htmlFor="aiResponseLength">AI response length</label>
                
                <AiRespSelector handleDropdownChange={handleDropdownChange} currentAiLength={aiResponseLength} />
            </li>
        </ul>

        <div className="mt-4 flex items-center gap-8 max-md:justify-between max-md:flex-col-reverse">

          <button onClick={handleRevertChanges} className="bg-white border-2 border-error text-error rounded-md p-4 max-md:p-3 max-md:w-full">Revert changes</button>
          <div className="relative max-md:w-full">
            <button onClick={handleSaveChanges} className="bg-primary border-2 border-primary rounded-md text-white p-4 disabled:bg-body disabled:border-gray-placeholder disabled:cursor-not-allowed max-md:p-3 max-md:w-full relative" disabled={loading}>Save Changes</button>
            {loading && <Spinner className="text-primary absolute top-5 mx-[50%]" size={1.5} />}
          </div>
          
        </div>
        
      </div>
    </div>
  );
};

export default PersonalizationContent;