import { useState, useEffect } from 'react';
import { fetchJournals, JournalEntry } from '../utils/api';

const useJournals = (fetchTrigger: number) => {
  const [journals, setJournals] = useState<JournalEntry[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getJournals = async () => {
      try {
        const journalEntries = await fetchJournals();
        setJournals(journalEntries);
      } catch (error) {
        console.error('Error fetching journals:', error);
      } finally {
        setLoading(false);
      }
    };

    getJournals();
  }, [fetchTrigger]);

  return { journals, loading };
};

export default useJournals;
