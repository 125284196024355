import React from 'react';
import SidebarToggle from './SideBarToggle';
import { getCurrentUser } from 'utils/api';
import SearchBar from './SearchBar';

interface TopBarProps {
  toggleSidebar: () => void;
  modalState: (state: boolean) => void;
  editorOpen: boolean;
}

const TopBar: React.FC<TopBarProps> = ({ toggleSidebar, modalState, editorOpen }) => {
  const _currentUser = getCurrentUser(); // get current user from localStorage


  // get the current user
  const user = getCurrentUser();

  // check for profile image
  const profileImage = (user?.profileUrl && user?.profileUrl !== '') ?  user?.profileUrl : 'https://img.icons8.com/color/48/user-male-circle--v1.png';

  const openModal = () => {
    modalState(true);
  };

  const convertToElipse = (text: string, length=15) => {
    if (text.length > length) {
        return text.substring(0, length) + '...';
    }
    return text;
  }

  return (
    <div className={`flex justify-between items-center pb-3 pl-10 max-md:px-0 pr-16 py-2 drop-shadow-sm bg-white dark:bg-bgDark ${editorOpen ? 'max-md:hidden':''}`}>
      <img src="/images/toice.png" alt="LOGO" className="w-8 h-8 ml-4 max-md:hidden cursor-pointer" />
      <div className="flex items-center md:space-x-5">
        <SidebarToggle toggleSidebar={toggleSidebar} />
        <SearchBar />
        <p className="text-ellipsis text-2xl ml-0 pl-0 text-header dark:text-white font-bold md:hidden  text-nowrap overflow-clip">{convertToElipse(`${_currentUser.name}'s Journal`)}</p>
      </div>
      <div className="flex items-center justify-between space-x-8">
        
        <ul className='flex space-x-8'>
          <li className="relative">
            <div className="flex items-center space-x-2">
            <img
              src={profileImage}
              alt="User Profile"
              className="w-8 h-8 max-md:w-12 max-md:h-12 max-sm:h-8 max-sm:w-8 rounded-full"
              onClick={openModal}
            />
            </div>
            {/* <div className="absolute top-full right-0 mt-1 bg-white text-black p-2 rounded shadow-md z-[999] hidden">
              <Link to="#myprofile" className="block px-4 py-2 hover:bg-gray-200">Profile</Link>
              <div onClick={openModal} className="block px-4 py-2 hover:bg-gray-200 hover:cursor-pointer">Settings</div>
              <Link to="/logout" className="block px-4 py-2 hover:bg-gray-200">Logout</Link>
            </div>  */}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TopBar;
