import { apiProps, useApi } from 'hooks/useApi';
import { Spinner } from "../../../tiptap/ui/Spinner";
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { NotifyErrorOptions, NotifyOptions } from 'utils/helper';
import Alert from 'components/Alert';
import { LucideEye, LucideEyeOff } from 'lucide-react';

interface ChangePasswordProps {
    setOption: (option: string) => void;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({ setOption }) => {
  
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState('eye-off');

    const [success, setSuccess] = useState<string | null>(null);
    const [isError, setError] = useState<string | null>(null);
    
  
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [ updateTrigger, setUpdateTrigger ] = useState<apiProps>({ data: null, method: 'POST', endpoint:''});
  const { loading, baseResponse } = useApi(updateTrigger);

  const handleSaveChanges = () => {
    const data = {
      currentPassword: currentPassword,
      newPassword: newPassword,
    };
    setUpdateTrigger({data: data, method: 'POST', endpoint: 'auth/change-password'});
    
  };

  const handleValidatePassword = (value: string) => {
    if (value !== '') {
        if (value === newPassword) {
        setConfirmPassword('true');
        } else {
        setConfirmPassword('false');
        }
    }else {
        setConfirmPassword('');
    }
  }

    useEffect(() => {
        if (baseResponse) {
            if (baseResponse.status) {
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
                // toast.success('Password updated successfully', NotifyOptions);
                setSuccess('Password updated successfully');
            }else {
                // toast.error(baseResponse.message, NotifyErrorOptions);
                setError(baseResponse.message);
            }
        }
    }, [baseResponse]);

    const handlePasswordRevealer = () => {
        if(type === 'password') {
            setType('text');
            setIcon('eye');
        }else {
            setType('password');
            setIcon('eye-off');
        }
    }

    const handleErrorDismiss = () => {
        setError(null);
    }

    const handleSuccessDismiss = () => {
        setSuccess(null);
    }

  return (
    <>
        <div className='flex max-md:items-center max-md:justify-between md:gap-x-8 md:border-b-2 border-gray-200 pb-4 mb-6 max-md:px-4'>
            <img src="/images/CaretLeft.svg" alt="" onClick={() => setOption('default')} />
            <h1 className=" text-xl font-semibold text-header dark:text-white">Change Auth Password</h1>
            <div></div>
        </div>
        <div className='max-md:px-4 max-md:flex-col max-md:space-y-8'>
            {/* Alert */}
        {/* Danger Alert */}
            { isError &&
                <Alert message={isError} dismissTime={4000} onDismiss={handleErrorDismiss} />
            }

            { success && 
                <Alert type="success" message={success} dismissTime={3000} onDismiss={handleSuccessDismiss} />
            }
            {/* Alerts ends here */}
            <p className="text-sm text-center text-header dark:text-white mb-6">Enhance your account security by updating your current password to a new, stronger one.</p>
            <div className="mb-4 flex relative">
                <input
                type={type}
                placeholder='Current Password'
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-highlight focus:border-highlight sm:text-sm dark:text-body"
                />
                <span className="absolute top-1/4 right-1" onClick={handlePasswordRevealer}>
                        { (icon === 'eye') ? <LucideEye className="w-6 h-6 text-gray-500 cursor-pointer pr-1" /> :
                            <LucideEyeOff className="w-6 h-6 text-gray-500 cursor-pointer pr-1" />
                        }
                        
                    </span>
            </div>
            <div className="mb-4 relative flex">
                <input
                type={type}
                value={newPassword}
                placeholder='New Password'
                minLength={6}
                min={6}
                onChange={(e) => setNewPassword(e.target.value)}
                className="dark:text-body mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-highlight focus:border-highlight sm:text-sm"
                />
                <span className="absolute top-1/4 right-1" onClick={handlePasswordRevealer}>
                    { (icon === 'eye') ? <LucideEye className="w-6 h-6 text-gray-500 cursor-pointer pr-1" /> :
                        <LucideEyeOff className="w-6 h-6 text-gray-500 cursor-pointer pr-1" />
                    }
                        
                </span>
                
            </div>
            <p className="text-xs text-gray-500 dark:text-white mt-1">* Password must be 6 characters long</p>
            <div className={`mt-4 ${confirmPassword === 'false' ? 'mb-1' : 'mb-6'}  flex relative`}>
                <input
                type={type}
                minLength={6}
                min={6}
                placeholder='Confirm Password'
                onChange={(e) => handleValidatePassword(e.target.value)}
                className={`dark:text-body mt-1 block w-full p-3 border ${confirmPassword === 'false' ? 'border-red-500': 'border-gray-placeholder'} rounded-md shadow-sm focus:outline-none focus:ring-highlight focus:border-highlight sm:text-sm`}
                />
                <span className="absolute top-1/4 right-1" onClick={handlePasswordRevealer}>
                    { (icon === 'eye') ? <LucideEye className="w-6 h-6 text-gray-500 cursor-pointer pr-1" /> :
                        <LucideEyeOff className="w-6 h-6 text-gray-500 cursor-pointer pr-1" />
                    }     
                </span>
                
            </div>
            {confirmPassword === 'false' && <p className="text-xs text-red-600 mt-1 mb-6">Password does not match</p>}
            <button
                onClick={handleSaveChanges}
                className={`w-40 float-right max-md:w-full bg-primary text-white py-3 px-6 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-placeholder focus:ring-highlight ${confirmPassword === 'false' ? 'cursor-not-allowed ' : ''}`}
                disabled={confirmPassword === 'false' || loading || confirmPassword === '' || newPassword.length < 6}
            >
                {!loading ?'Save Changes' : <Spinner className="text-white" size={1.5} />}
            </button>
        </div>
        
    </>
  );
};

export default ChangePassword;
