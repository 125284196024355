import { useState, useEffect } from "react";
import { fetchTags } from "utils/api";
import { Tag } from "utils/helper";

// Define the useTags hook
export const useTags = () => {
    const [tags, setTags] = useState<Tag[]>([]);
    const [tagsLoading, setLoading] = useState<boolean>(true);
  
    useEffect(() => {
      const getTags = async () => {
        try {
          const journalTags = await fetchTags();
          setTags(journalTags);
        } catch (error) {
          console.error('Error fetching tags:', error);
        } finally {
          setLoading(false);
        }
      };
  
      getTags();
    }, []);
  
    return { tags, tagsLoading };
  };