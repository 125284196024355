import React, {useEffect} from "react";


interface ToastProps {
    message: string;
    type: "success" | "error" | "warning" | "info";
    onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({message, type, onClose}) => {

    useEffect(() => {
        const timer = setTimeout(onClose, 5000);

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div 
            className={`toast toast-${type} shadow-md`}
            style={{
                position: "fixed",
                top: "1rem",
                left: "50%",
                transform: "translateX(-50%)",
                padding: "1rem",
                borderRadius: "0.5rem",
                backgroundColor: "#fff",
                color: type === 'success' ? '#3070CF': type === 'error' ? '#B40101' : type === 'warning' ? '#FFA500' : '#F0E68C'
            }}
        >
            {type === 'success' && <img className="w-10 h-10 border-2 rounded-[99%] inline p-0.5 bg-[#7ea5df] mr-2" src="/images/CheckCircle.svg" alt="check" />}
            {type !== 'success' && <img className="w-10 h-10 border-2 rounded-[97%] inline p-0.5 bg-[#d18f8f] mr-2" src="/images/WarningCircle.svg" alt="warn" />} {message}          
        </div>
    );
};

export default Toast;