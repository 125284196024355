import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ExternalAuth, RegistrationEntry } from '../../utils/api';
import { useRegister } from '../../hooks/useRegister';
import { Spinner } from "../../tiptap/ui/Spinner";
import { toast } from 'react-toastify';
import { NotifyErrorOptions } from 'utils/helper';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { useApi } from 'hooks/useApi';
import { useAuth } from 'context/AuthContext';
import { LucideEye, LucideEyeOff } from 'lucide-react';
import Alert from 'components/Alert';

const Register: React.FC = () => {

    const [type, setType] = useState('password');
    const [icon, setIcon] = useState('eye-off');

    const [ regDetails, setRegDetails ] = useState<RegistrationEntry>({name: '', email: '', password: ''});
    const [ regTrigger, setRegTrigger ] = useState<RegistrationEntry | null>(null);
    const { baseResponse } = useRegister(regTrigger);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [ extTrigger, setExtTrigger ] = useState<ExternalAuth | null>(null);
    const { loading:extLoading, baseResponse:extBaseResponse } = useApi({data: extTrigger, method: 'POST', endpoint: 'auth/create-external'});


    const navigate = useNavigate();
    const { login } = useAuth();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setRegDetails((prev) => ({...prev, [name]: value}));

        // check if the name length is greater than 3
        if(name === 'name')
        {
            if(value.length < 3)
            {
                setError('Name is too short...');
                return;
            } else {
                setError(null);
            }
        }

        // check if the input for email is valid
        if(name === 'email')
        {
            // validate against email regex
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                setError('Invalid email address');
                return;
            } else {
                setError(null);
            }
        }
    }

    // Handle form submission
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // validate password
        if(regDetails.password.length < 6)
        {
            setError('Password must be at least 6 characters');
            return;
        }

        setError(null);


        setLoading(true);

        setRegTrigger(regDetails);
        
    }

    // Handle External Login (Google, Facebook, etc.)
    const handleGoogleLogin = (response: TokenResponse) => {
        // check if it's invalid response
        if(!response.access_token)
        {
            setError('Invalid Google response');
            return;
        }

        setExtTrigger({provider: 'GOOGLE', idToken: response.access_token});
        
    }

    // the setError and addToast functions are being called directly in the Login component render method, 
    // which causes the component to re-render infinitely.
    // Resolve this issue by moving the setError and addToast functions to a useEffect hook that listens for changes in the baseResponse and error states.
    useEffect(() => {

        if(baseResponse) { 
            setLoading(false);
            if (!baseResponse.status) {
                // Only show toast if there's an error and it hasn't been shown yet
                if(error !== baseResponse.message){
                    setError(baseResponse.message);
                    setRegDetails((prev) => ({...prev, password: ''}));
                }

            } else if (baseResponse.status) {
                // showToast(baseResponse.message, 'success');
                setSuccess(baseResponse.message);
                
                // redirect to Next Page (Validation) Component
                // delay for 1 second

                setTimeout(() => {
                    navigate('/register/verify');
                }, 2000);
            }
        }
    }, [baseResponse, error, navigate]);

    // Use the useEffect hook to listen for changes in the external login BaseResponse state.
    // If the response is successful, set the token in local storage and redirect the user to the editor page.
    useEffect(() => {
        if(extBaseResponse) {
            if(extBaseResponse.status) {

                setSuccess(extBaseResponse.message);
                // set token
                login(extBaseResponse.data.accessToken, JSON.stringify(extBaseResponse.data.user));
                // redirect to editor page
                // delay for 1 second
                setTimeout(() => {
                    navigate('/editor', { replace: true });
                }, 1000);
            } else {
                setError(extBaseResponse.message);
                setExtTrigger(null);
            }
        }
    }, [extBaseResponse, navigate]);

    // Handle Password Reveal
    const handlePasswordRevealer = () => {
        if(type === 'password') {
            setType('text');
            setIcon('eye');
        }else {
            setType('password');
            setIcon('eye-off');
        }
    }

    // Google Login Hook
    const loginWithGoogle = useGoogleLogin({
        onSuccess: (response: TokenResponse) => handleGoogleLogin(response),
        onError: (error) => toast.error(error.error_description, NotifyErrorOptions)
    });

    const handleErrorDismiss = () => {
        setError(null);
    }

    const handleSuccessDismiss = () => {
        setSuccess(null);
    }
    
    return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 pt-4 pb-8 lg:px-8 authPage  max-md:mt-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm md:mt-2">
        <img alt="logo" className="mx-auto h-10 w-auto max-md:w-16 max-md:h-16 " src="/images/toice.png" />
        <h2 className="mt-6 text-2xl font-bold text-center text-gray-900 leading-9 tracking-tight">Create an account</h2>
      </div>

      <div className="mt-7 sm:mx-auto sm:w-full sm:max-w-sm">
        {/* Alert */}
        {/* Danger Alert */}
        { error &&
            <Alert message={error} dismissTime={3000} onDismiss={handleErrorDismiss} />
        }

        { success && 
            <Alert type="success" message={success} dismissTime={3000} onDismiss={handleSuccessDismiss} />
        }
        {/* Alerts ends here */}
        <form className="space-y-4" onSubmit={handleSubmit} method="POST">
            <div>
                <label htmlFor="name" className="block text-sm font-medium leading-6 text-black">Username</label>
                <div className="mt-2">
                    <input id="name" placeholder="Username" name="name" onChange={handleChange} type="text" value={regDetails.name} required autoComplete="name" className="block w-full p-3 border-2 rounded-md placeholder:text-gray-400 focus:border-2 focus:border-primary transition duration-150 ease-in-out sm:text-sm outline-none" />
                </div>
            </div>
            <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-black">Email address</label>
                <div className="mt-2">
                    <input id="email" placeholder="Email address" name="email" onChange={handleChange} type="email" value={regDetails.email} required autoComplete="email" className="block w-full p-3 border-2 rounded-md placeholder:text-gray-400 focus:border-2 focus:border-primary transition duration-150 ease-in-out sm:text-sm outline-none" />
                </div>
            </div>

            <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-black">Password</label>   
                <div className="mt-2">
                    
                </div>
                <div className="mt-2 flex relative">
                    <input id="password" placeholder="Password" name="password" type={type} onChange={handleChange} value={regDetails.password} required autoComplete="current-password" className="block w-full rounded-md border-2 p-3 placeholder:text-gray-400 focus:border-2 focus:border-primary transition duration-150 ease-in-out sm:text-sm outline-none" />
                    <span className="absolute top-1/4 right-1" onClick={handlePasswordRevealer}>
                        { (icon === 'eye') ? <LucideEye className="w-6 h-6 text-gray-500 cursor-pointer pr-1" /> :
                            <LucideEyeOff className="w-6 h-6 text-gray-500 cursor-pointer pr-1" />
                        }
                        
                    </span>
                </div>
            </div>

            { (loading || extLoading) &&
                <div className="flex items-center justify-center rounded-lg bg-opacity-80">
                    <Spinner className="text-primary" size={1.5} />
                </div>
            }


            <button type="submit" className="block w-full mt-7 p-3 border rounded-md text-sm font-medium text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition duration-150 ease-in-out disabled:bg-slate-400" disabled={loading}>Continue</button>


            <span className="block text-center text-sm font-medium text-gray-400">or</span>

            <button onClick={() => {loginWithGoogle()}} className="w-full p-3 border rounded-lg text-sm font-medium text-black bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition duration-150 ease-in-out flex items-center justify-center">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                    <path d="M23.0015 12.2331C23.0015 11.3697 22.93 10.7397 22.7753 10.0864H12.7158V13.983H18.6205C18.5015 14.9514 17.8587 16.4097 16.4301 17.3897L16.41 17.5201L19.5907 19.9349L19.811 19.9564C21.8348 18.1247 23.0015 15.4297 23.0015 12.2331Z" fill="#4285F4"/>
                    <path d="M12.715 22.5001C15.6078 22.5001 18.0363 21.5667 19.8102 19.9567L16.4292 17.39C15.5245 18.0083 14.3102 18.44 12.715 18.44C9.88167 18.44 7.47693 16.6083 6.61971 14.0767L6.49406 14.0871L3.18681 16.5955L3.14355 16.7133C4.90543 20.1433 8.52448 22.5001 12.715 22.5001Z" fill="#34A853"/>
                    <path d="M6.61997 14.0767C6.39379 13.4234 6.26289 12.7233 6.26289 12C6.26289 11.2767 6.39379 10.5767 6.60807 9.92337L6.60208 9.78423L3.25337 7.2356L3.14381 7.28667C2.41765 8.71002 2.00098 10.3084 2.00098 12C2.00098 13.6917 2.41765 15.29 3.14381 16.7133L6.61997 14.0767Z" fill="#FBBC05"/>
                    <path d="M12.715 5.55997C14.7269 5.55997 16.084 6.41163 16.8579 7.12335L19.8817 4.23C18.0246 2.53834 15.6078 1.5 12.715 1.5C8.52451 1.5 4.90544 3.85665 3.14355 7.28662L6.60783 9.92332C7.47696 7.39166 9.88171 5.55997 12.715 5.55997Z" fill="#EB4335"/>
                </svg>
Sign up with Google
            </button>
                

            <div className="text-center">
                <span>Already have an account? <Link to="/login" className="text-primary no-underline font-bold">Log in</Link></span>
            </div>
        </form>

        <div className="mt-5">
            <p className="text-center text-sm font-medium text-[#888]">By signing in, you agree with our<br />
            <Link to="#terms" className="text-[#333] no-underline font-bold">Terms & Conditions</Link> and <Link to="#privacy" className="text-[#333] no-underline  font-bold">Privacy Policy</Link>. </p>
        </div>

      </div>
    </div>

    );
};

export default Register;