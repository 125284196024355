import { useVerifyFPassword } from "hooks/useRegister";
import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { toast } from "react-toastify";
import { TokenEntry } from "utils/api";
import { NotifyErrorOptions, NotifyOptions } from "utils/helper";

export const FPVerifyOtp: React.FC = () => {

    const [pin, setPin] = useState<string>('');
    const [ verifyTrigger, setVerifyTrigger ] = useState<TokenEntry | null>(null);
    const { loading, baseResponse } = useVerifyFPassword(verifyTrigger);
    // const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    
    const navigate = useNavigate();

    const currentEmail = localStorage.getItem("reg:email");

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setError(null);
        // setLoading(true);

        //get temp userId stored on localStorage
        const userId = localStorage.getItem("reg:id");

        if(!userId) {
            setError('Invalid user');
            return;
        }

        // set verifyTrigger
        setVerifyTrigger({userId: userId, token: pin});

    };

    useEffect(() => {
        if (baseResponse) {
            // setLoading(false);
            if(!baseResponse.status) { 
                if(error !== baseResponse.message){
                    setError(baseResponse.message);
                    toast.error(baseResponse.message, NotifyErrorOptions);
                    setPin('');
                }
            }else if(baseResponse.status) {
                // showToast(baseResponse.message, 'success');
                toast.success(baseResponse.message, NotifyOptions);
                setTimeout(() => {
                    navigate('/forget-password/new-password', { replace: true });
                }, 1000);
            }
        } else {
            return;
        }
        
    }, [baseResponse, error, navigate]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 ">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        
            <h2 className="mt-10 text-2xl font-bold text-justify text-gray-900 leading-9 tracking-tight">Enter verification code</h2>
            <h4 className="block text-sm font-medium leading-6 text-[#333333]">Enter the 6-digit code that was sent to your email {currentEmail}</h4>
        </div>

      <div className="mt-7 sm:mx-auto sm:w-full sm:max-w-sm">
        
        <form className="space-y-6" onSubmit={handleSubmit} method="POST">
            
            <div>
                
                <OTPInput inputStyle={{
                    width: "2rem",
                    height: "2rem",
                    fontSize: "2rem",
                    marginRight: "1rem",
                    border: "1px solid rgba(0,0,0,0.3)",
                    borderRadius: "4px",
                }} shouldAutoFocus value={pin} onChange={setPin} numInputs={6} inputType="tel" containerStyle={`p-4`} placeholder="------"
                    renderInput={(props) => <input {...props}  />}  />
            </div>
            
            { loading &&
                <div className="flex flex-1 flex-col items-center">
                    <FadeLoader
                        color="#3070cf"
                        height={6}
                        speedMultiplier={2}
                    />
                    <span className="text-sm font-medium text-gray-500 mt-2">Please wait...</span>
                </div>
            }

            <div>
                <button type="submit" className="block w-full mt-5 p-3 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition duration-150 ease-in-out disabled:bg-slate-400" disabled={pin.trim().length < 6 }>Verify</button>
            </div>

        </form>

      </div>
    </div>
  );
};