import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from 'lucide-react';



interface NavbarProps {
  featureRef: React.RefObject<HTMLDivElement>;
  faqRef: React.RefObject<HTMLDivElement>;
}

const Navbar: React.FC<NavbarProps> = ({ featureRef, faqRef }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();

  return (
    <nav className="bg-bg-200 dark:bg-gray-800  px-4 md:px-32" >
      <div className="container mx-auto py-2 flex justify-between items-center">
        <div className="text-2xl font-bold" onClick={() => navigate('/')}><img src='/images/toice.png' alt='Toice' className='w-10 h-10' /></div>
        <div className="flex-grow flex justify-center md:justify-center space-x-4 text-primary max-md:hidden">
          <Link to="/" className=" dark:text-gray-300">Home</Link>
          <span className="text-gray-300 dark:text-gray-300">|</span>
          <Link to="#features" onClick={() => (featureRef.current?.scrollIntoView({behavior: 'smooth'}))} className="cursor-pointer  dark:text-gray-300">Features</Link>
          <span className="text-gray-300 dark:text-gray-300">|</span>
          <Link to="#faqs" className=" dark:text-gray-300" onClick={() => (faqRef.current?.scrollIntoView({behavior: 'smooth'}))}>FAQs</Link>
          </div>
        <div className="flex justify-end max-md:hidden">
          <button className="px-4 py-2 bg-transparent border-[1px] border-primary text-primary rounded-lg"
            onClick={() => navigate('/register')}
          >
            Sign up <ArrowRightIcon className="h-4 w-4 inline-block text-primary" />
          </button>
        </div>
        <div className="md:hidden">
          <button
            className="text-gray-700 dark:text-gray-300 focus:outline-none"
            onClick={toggleMenu}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              />
            </svg>
          </button>
        </div>
      </div>
      <div className={`${isOpen ? "block" : "hidden"} md:hidden text-primary`}>
        <Link to="/" className="block px-4 py-2  dark:text-gray-300">
          Home
        </Link>
        <Link to="#features" className="block px-4 py-2 dark:text-gray-300" onClick={() => (featureRef.current?.scrollIntoView({behavior: 'smooth'}))}>
          Features
        </Link>
        <Link to="#faqs" className="block px-4 py-2 dark:text-gray-300" onClick={() => (faqRef.current?.scrollIntoView({behavior: 'smooth'}))}>
          FAQs
        </Link>
        <button className="px-4 py-2 bg-transparent border-[1px] border-primary text-primary rounded-lg"
        onClick={() => navigate('/register')}
        >
          Sign up <ArrowRightIcon className="h-4 w-4 inline-block text-primary" />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;