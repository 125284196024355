import { apiProps, useApi } from 'hooks/useApi';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { NewPasswordEntry } from 'utils/api';
import { NotifyErrorOptions, NotifyOptions } from 'utils/helper';


export const NewPassword: React.FC = () => {
    const endpoint = 'auth/reset-password';
    // get the email and token from the localStorage
    const email = localStorage.getItem('reg:email') ?? '';
    const token = localStorage.getItem('reg:token') ?? '';

    // password and confirm password
    const [password, setPassword] = useState<string>('');
    const [cpassword, setCPassword] = useState<string>('');

    const [params, setParams] = useState<NewPasswordEntry>({email: email, token: token, newPassword: password});

    const [ resetTrigger, setResetTrigger ] = useState<apiProps>({data: null, method: 'POST', endpoint: endpoint});
    const { loading, baseResponse } = useApi(resetTrigger);
    const [error, setError] = useState<string | null>(null);

    const [isValid, setIsValid] = useState<boolean>(true);
    const [match, setMatch] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setCPassword(value);
        
        // confirm the confirm password matches the password
        if(value !== password) {
            setError('Passwords do not match');
            setMatch(false);
            return;
        }

        setIsValid(false);
        setMatch(true);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setError(null);

        setParams({email: email, token: token, newPassword: password});

        setResetTrigger((prev) => ({...prev, data: {email: email, token: token, newPassword: password}}));
        
    }

    // the setError and addToast functions are being called directly in the Login component render method, 
    // which causes the component to re-render infinitely.
    // Resolve this issue by moving the setError and addToast functions to a useEffect hook that listens for changes in the baseResponse and error states.
    useEffect(() => {

        if(baseResponse) { 
            if (!baseResponse.status) {
                // Only show toast if there's an error and it hasn't been shown yet
                if(error !== baseResponse.message){
                    setError(baseResponse.message);
                    toast.error(baseResponse.message, NotifyErrorOptions);
                    setResetTrigger((prev) => ({...prev, data: null}));
                    setPassword('');
                    setCPassword('');
                }

            } else if (baseResponse.status) {
                toast.success(baseResponse.message, NotifyOptions);
                
                // redirect to Next Page (Validation) Component
                // delay for 1 second

                setTimeout(() => {
                    navigate('/login', { replace: true });
                }, 1000);
            }
        }
    }, [baseResponse, error, navigate]);
    
    return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 ">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-2xl font-bold text-gray-900 leading-9 tracking-tight">Create new password</h2>
      </div>

      <div className="mt-7 sm:mx-auto sm:w-full sm:max-w-sm">
        
        <form className="space-y-6" onSubmit={handleSubmit} method="POST">
            <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-black">New Password</label>
                <div className="mt-2">
                    <input id="password" placeholder="New password" name="password" onChange={(e) => setPassword(e.target.value)} type="password" value={password} required autoComplete='new-password' className="block w-full p-3 border-2 rounded-md shadow-sm placeholder:text-gray-400 focus:border-2 focus:border-primary transition duration-150 ease-in-out sm:text-sm sm:leading-6 outline-none" />
                </div>
            </div>

            <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-black">Confirm Password</label>   
                <div className="mt-2">
                    <input id="cpassword" placeholder="Confirm Password" name="cpassword" type="password" onChange={handleChange} value={cpassword} required autoComplete="new-password" className="block w-full p-3 rounded-md border-2 shadow-sm placeholder:text-gray-400 focus:border-2 focus:border-primary transition duration-150 ease-in-out sm:text-sm sm:leading-6 outline-none" />
                </div>
                {!match && cpassword.length > 0 && <span className="text-sm font-medium text-red-500 mt-1">* Passwords does not match</span>}
            </div>

            { loading &&
                <div className="flex flex-1 flex-col items-center">
                    <FadeLoader
                        color="#3070cf"
                        height={6}
                        speedMultiplier={2}
                    />
                    <span className="text-sm font-medium text-gray-500 mt-2">Please wait...</span>
                </div>
            }

            <div>
                <button type="submit" className="block w-full mt-5 p-3 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition duration-150 ease-in-out disabled:bg-slate-400" disabled={loading || isValid}>Continue</button>
            </div>

        </form>

      </div>
    </div>

    );
};