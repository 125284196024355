import React, { useEffect, useState } from "react";
import { apiProps, useApi } from "hooks/useApi";
import { format } from "date-fns";
import { Spinner } from "../../../tiptap/ui/Spinner";
import Alert from "components/Alert";
import { useTheme } from "context/ThemeContext";


interface PersonalContentProps {
  modelState: boolean;
  setModelState: (state: boolean) => void;
}

const ExportContent: React.FC<PersonalContentProps> = ({modelState, setModelState}) => {
    
    const [beginDate, setBeginDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [ updateTrigger, setUpdateTrigger ] = useState<apiProps>({ data: null, method: 'POST', endpoint:''});
    const { loading, baseResponse } = useApi(updateTrigger)
    const [success, setSuccess] = useState<string | null>(null);
    const [isError, setError] = useState<string | null>(null);
    const {isDarkMode} = useTheme();

    
    // Handle Save Changes
    const handleSaveChanges = () => {
      let type = "date";
      let bDate = format(beginDate, 'yyyy-MM-dd HH:mm:ss.SSS');
      let eDate = format(endDate, 'yyyy-MM-dd HH:mm:ss.SSS');
    
      setUpdateTrigger({data: {}, method: 'GET', endpoint: `journals/export-all?Type=${type}&BeginDate=${bDate}&EndDate=${eDate}`});
    }

    useEffect(() => {
      async function fetchData() {
        if(baseResponse){
          if(baseResponse.status) {
            setSuccess(baseResponse.message);
            // toast.success(baseResponse.message, NotifyOptions);
            // console.log('Update response:', baseResponse);
          }else {
            setError(baseResponse.message);
            // toast.error(baseResponse.message, NotifyErrorOptions);
          }
        }
      }
      fetchData();
    },[baseResponse]);

    const handleErrorDismiss = () => {
      setError(null);
    }
  
    const handleSuccessDismiss = () => {
      setSuccess(null);
    }

  return (
    <div className={`${modelState ? 'max-md:visible':'max-md:hidden'} max-md:px-4`}>
      <div className="flex max-md:items-start max-md:justify-between md:border-b-2 border-gray-200">
        {!isDarkMode ? 
          <img className="md:hidden" src="/images/CaretLeft.svg" alt="" onClick={() => setModelState(false)} />
            :
          <img className="md:hidden" src="/images/darkCaretLeft.svg" alt="" onClick={() => setModelState(false)} />
        }
        <h1 className="text-xl font-semibold text-header pb-4 dark:text-white">Export</h1>
        <div></div>
      </div>

        <div className="mt-4 flex items-center mb-6">
          <p className="text-[16px] font-medium">Export Journal</p>
        </div>
        {/* Alert */}
        {/* Danger Alert */}
        { isError &&
            <Alert message={isError} dismissTime={4000} onDismiss={handleErrorDismiss} />
        }

        { success && 
            <Alert type="success" message={success} dismissTime={3000} onDismiss={handleSuccessDismiss} />
        }
        {/* Alerts ends here */}
        <div className="flex items-center justify-between py-4 font-sans mb-4">
            <div>
                <h2 className="text-sm font-medium">Export data as</h2>
                <p className="text-xs text-body">Your data will be exported in a PDF format</p>
            </div>
            <img className="ml-auto md:hidden" src="/images/settings/CaretRight.svg" alt="Right" />
        </div>
        <div className="flex flex-col py-4 font-sans mb-8">
            <h2 className="text-sm font-medium pb-1">Select date range</h2>
            
            <div className="flex items-center max-md:justify-between md:space-x-4 ">
                <input onChange={(e) => setBeginDate(new Date(e.target.value))} type="date" className="border-2 border-gray-placeholder rounded-md p-2 dark:text-body" value={beginDate?.toISOString().split('T')[0]} />
                <span className="mx-2">to</span>
                <input onChange={(e) => setEndDate(new Date(e.target.value))} type="date" className="border-2 border-gray-placeholder rounded-md p-2 dark:text-body" value={endDate?.toISOString().split('T')[0]} />
            </div>
        </div>
        { loading &&
              <div className="flex items-center justify-center rounded-lg bg-opacity-80">
                  <Spinner className="text-primary" size={1.5} />
              </div>
            }
        <div className="mt-4 md:mb-5 flex items-center md:justify-end">
          <button onClick={handleSaveChanges} className="bg-primary border-2 border-primary rounded-md text-white p-4 disabled:bg-body disabled:border-gray-placeholder disabled:cursor-not-allowed max-md:p-3 max-md:w-full w-1/2" disabled={loading}>Export</button>
        </div>

    </div>
  );
};

export default ExportContent;