import React, { useEffect, useState } from "react";

// SlideProps
interface Slide {
    id: number;
    imageUrl: string;
    caption: string;
    overlayImageIcon: string;
}

// Props
interface CarouselProps {
    slides: Slide[];
    interval?: number;
}

export const Carousel: React.FC<CarouselProps> = ({ slides, interval }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const slideinterval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
        }, interval);

        return () => clearTimeout(slideinterval);
    }, [interval, slides.length]);

    const goToSlide = (index: number) => {
        setCurrentIndex(index);
      };


  return (
    <div className="flex flex-col items-center bg-[#F6F9FA] py-24">
      {/* Headline */}
      <h2 className="text-center text-3xl font-bold mb-10 md:w-[40%]">
        Transform every milestone into <span className="text-primary">memories</span> you’ll hold close for a lifetime
      </h2>

      {/* Carousel */}
      <div className="relative w-full mx-auto overflow-clip">
        <div className="flex transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${currentIndex * 500}px)` }}>
          {slides.map((slide) => (
            <div key={slide.id} className="w-[738px] h-[412px] flex-shrink-0 ml-10 relative">
              <img src={`${slide.imageUrl}`} alt={slide.caption} className="rounded-lg w-full h-full object-cover" />
              <div className="absolute bottom-4 left-4 flex items-center space-x-2 bg-opacity-75 bg-black text-white p-2 rounded-md">
                <span className="text-lg">{slide.overlayImageIcon}</span>
                <p className="text-sm">{slide.caption}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Navigation Dots */}
      <div className="flex space-x-4 mt-12">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-3 h-3 rounded-full ${
              currentIndex === index ? 'bg-primary' : 'bg-gray-300'
            }`}
          />
        ))}
      </div>
    </div>
  );
}


export const MemoryCarousel: React.FC = () => {
    const slides: Slide[] = [
        {
            id: 1,
            imageUrl: '/images/carousel/soccer-girl.jpg',
            caption: 'Got your first football award',
            overlayImageIcon: '⚽',
        },
        {
            id: 2,
            imageUrl: '/images/carousel/cycling-boy.jpg',
            caption: 'First cycling class',
            overlayImageIcon: '🚲',
        },
        {
            id: 3,
            imageUrl: '/images/carousel/dancing-girl.jpg',
            caption: 'Danced your heart out',
            overlayImageIcon: '💃',
        },
        {
            id: 4,
            imageUrl: '/images/carousel/party-girl.jpg',
            caption: 'Visited a new art gallery',
            overlayImageIcon: '🖼️',
        },
        {
            id: 5,
            imageUrl: '/images/carousel/airport.jpg',
            caption: 'Landed in a new country',
            overlayImageIcon: '✈️',
        },
    ];

    return <Carousel slides={slides} interval={3000} />;
}