import { apiProps, useApi } from "hooks/useApi";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { toast } from "react-toastify";
import { ForgetPasswordEntry } from "utils/api";
import { NotifyErrorOptions, NotifyOptions } from "utils/helper";

export const ForgetPassword: React.FC = () => {
    const endpoint = 'auth/forget-password';

    const [email, setEmail] = useState<string>('');
    const [params, setParams] = useState<ForgetPasswordEntry>({email: email});

    const [ verifyTrigger, setVerifyTrigger ] = useState<apiProps>({data: null, method: 'POST', endpoint: endpoint});
    const { loading, baseResponse } = useApi(verifyTrigger);
    // const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    
    const navigate = useNavigate();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setError(null);
        // setLoading(true);

        //check if email is valid 
        const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if(!emailRegEx.test(email)) {
            setError('Invalid email');
            toast.error('Invalid email', NotifyErrorOptions);
            return;
        }

        console.log("Email:", email);

        // set verifyTrigger
        setParams({email: email});
        console.log("Params:", params);


        setVerifyTrigger({data: {email: email}, method: 'POST', endpoint: endpoint});

    };

    useEffect(() => {
        if (baseResponse) {
            // setLoading(false);
            if(!baseResponse.status) { 
                if(error !== baseResponse.message){
                    setError(baseResponse.message);
                    // showToast(baseResponse.message, 'error');
                    toast.error(baseResponse.message, NotifyErrorOptions);
                    setEmail('');
                }
            }else if(baseResponse.status) {
                // showToast(baseResponse.message, 'success');
                localStorage.setItem('reg:id', baseResponse.data.userId);
                localStorage.setItem('reg:email', email);
                toast.success(baseResponse.message, NotifyOptions);
                setTimeout(() => {
                    navigate('/forget-password/verify', { replace: true });
                }, 500);
            }
        } else {
            return;
        }
        
    }, [baseResponse, error, navigate]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 ">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-2xl font-bold text-justify text-gray-900 leading-9 tracking-tight">Forget Password?</h2>
            <h4 className="block text-sm font-medium leading-6 text-[#333333]">Don’t worry, we’ve got you covered.<br/>Enter the email associated with your account.</h4>
        </div>

      <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
        
        <form className="space-y-6" onSubmit={handleSubmit} method="POST">
            
            <div>
                <div className="mt-2">
                    <input id="email" placeholder="Email address" name="email" onChange={(e) => setEmail(e.target.value)} type="email" value={email} required autoComplete="email" className="block w-full p-3 border-2 rounded-md shadow-sm placeholder:text-gray-400 focus:border-2 focus:border-primary transition duration-150 ease-in-out sm:text-sm sm:leading-6 outline-none" />
                </div>
            </div>
            
            { loading &&
                <div className="flex flex-1 flex-col items-center">
                    <FadeLoader
                        color="#3070cf"
                        height={6}
                        speedMultiplier={2}
                    />
                    <span className="text-sm font-medium text-gray-500 mt-2">Please wait...</span>
                </div>
            }

            <div>
                <button type="submit" className="block w-full mt-5 p-3 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition duration-150 ease-in-out disabled:bg-slate-400" disabled={email.trim().length < 6 }>Continue</button>
            </div>

        </form>

      </div>
    </div>
  );
};