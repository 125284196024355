import React, { useState } from "react";
import ChangePassword from "../security/ChangePassword";
import { useTheme } from "context/ThemeContext";

interface PasswordContentProps {
  defaultOption: string;
  modelState: boolean;
  setModelState: (state: boolean) => void;
}

const PasswordContent: React.FC<PasswordContentProps> = ({ defaultOption, modelState, setModelState}) => {
  // get user profile from localStorage
  // const user = JSON.parse(localStorage.getItem('user') || '{}');
  // const [enabled, setEnabled] = useState(user.isAppLocked || false);
  const [optionSet, setOption] = useState<string>(defaultOption);
  const { isDarkMode } = useTheme();

  const renderContent = () => {
    switch (optionSet) {
      case 'auth':
        return <ChangePassword setOption={setOption} />;
      default:
        return <SecurityOption setOption={setOption} setStateModel={setModelState} darkMode={isDarkMode} />;
    }
  }
      return (
        <div className={`${modelState ? 'max-md:visible':'max-md:hidden'} md:h-[29rem] md:overflow-y-auto`}>
          {renderContent()}
        </div>
        
      );
};


interface AuthOptionProps {
  title: string;
  description: string;
  setOption: (option: string) => void;
  
}

const AuthOption: React.FC<AuthOptionProps> = ({ title, description, setOption }) => (
  
  <div className="flex items-center justify-between py-4 font-sans" onClick={() => setOption('auth')}>
    <div>
      <h2 className="text-sm font-medium">{title}</h2>
      <p className="mt-1 text-xs w-3/4 text-body dark:text-white text-wrap">{description}</p>
    </div>
    
    <img className="ml-auto md:hidden" src="/images/settings/CaretRight.svg" alt="Right" />
  </div>
);

interface SecurityProps {
  setOption: (option: string) => void;
  setStateModel: (state: boolean) => void;
  darkMode: boolean;
}

const SecurityOption: React.FC<SecurityProps> = ({ setOption, setStateModel, darkMode }) => (
  
  <>
  <div className="flex max-md:items-start max-md:justify-between md:border-b-2 border-gray-200 max-md:px-4">
    {!darkMode ? 
        <img className="md:hidden" src="/images/CaretLeft.svg" alt="" onClick={() => setStateModel(false)} />
        :
        <img className="md:hidden" src="/images/darkCaretLeft.svg" alt="" onClick={() => setStateModel(false)} />
    }
    <h1 className="text-xl font-semibold text-header dark:text-white pb-4">Security</h1>
    <div></div>
  </div>
  

          <div className="bg-white dark:bg-bgDark w-full  my-8 max-md:my-3 max-md:px-3">
            <h3 className="text-lg font-medium  py-2">Authentication Password</h3>
            <AuthOption setOption={setOption} 
              title="Change Authentication Password"
              description="Set or update the password used for logging in and verifying your identity."
            />
            
          </div>

          {/* <hr className="text-gray-placeholder" /> */}
          {/* <h3 className="text-lg font-medium  py-2">App Lock Password</h3>
          <div className="flex items-center justify-between mt-2">
            <div className="mt-4">
              <div className="text-sm font-medium">Set Password</div>
              <p className="text-body text-[12px]">Set a password to protect your diary.</p>
            </div>
          
            <Switch
              checked={enabled}
              onChange={setEnabled}
              className={`${enabled ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Enable password protection</span>
              <span
                className={`${enabled ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform bg-white rounded-full transition`}
              />
            </Switch>
          </div> */}
  </>
);

export default PasswordContent;