import { Navigate, Outlet } from "react-router-dom";
import { ToastOptions } from "react-toastify";

export const GuestRoute = ({redirectPath = '/editor'}) => {
    const token = localStorage.getItem("token");
    
    return (!token ? <Outlet /> : <Navigate to={redirectPath} />);
    
};

export const NotifyOptions: ToastOptions = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
        backgroundColor: "#FFFFFF",
        color: "#3070CF",
        fontSize: "16px",
        fontWeight: "bold",
        boxShadow: "0px 4px 8px 0px #3333331A",
        borderRadius: "0px, 0px, 12px, 12px"
    }
};

export const NotifyErrorOptions: ToastOptions = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
        backgroundColor: "#FFFFFF",
        color: "#B40101",
        fontSize: "16px",
        fontWeight: "bold",
        boxShadow: "0px 4px 8px 0px #3333331A",
        borderRadius: "0px, 0px, 12px, 12px"
    }
};

export interface Tag {
    label: string;
    color: string;
  }

export interface AiPrefUpdate
{
    aiName: string;
    aiPersonality: string;
    aiLength: string;
}