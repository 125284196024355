import { UploadFormDataAPI } from "utils/api";

export class API {
  public static uploadImage = async (_file: File) => {
    
    const formData = new FormData();
      formData.append('formCollection', _file);

      const response = await UploadFormDataAPI('storages/upload', formData);

      // wait for the response before timeout
      await new Promise((resolve) => setTimeout(resolve, 1000));

    if(response.status) {
      // console.log('Image uploaded successfully:', response.message);
      return response.message;
    }

    console.log('Error uploading image:', response.message);
    return '/placeholder.jpg';
  }
}

export default API
