import { v4 as uuidv4 } from 'uuid';
import { Tag } from './helper';
import Cookies from 'js-cookie';

export const API_BASE_URL: string = process.env.REACT_APP_API_BASE_URL ?? '';

export interface LoginEntry {
  email: string;
  password: string;
}

export interface RegistrationEntry {
  name: string;
  email: string;
  password: string;
}

export interface NewPasswordEntry {
  email: string;
  token: string;
  newPassword: string;
}

export interface ForgetPasswordEntry {
  email: string;
}

export interface TokenEntry {
  userId: string;
  token: string;
}

export interface BaseResponse {
  status: boolean;
  message: string;
  data: any;
}

  export interface JournalEntry {
    id?: string;
    userId?: string; 
    title: string;
    mood?: string;
    isLocked: boolean;
    tags: string[];
    entries: Entry[];
    metadata?: object; 
    journalDate: string;
    tempId?: string;
  }

  export interface Entry {
    id?: string;
    entryType: string;
    content: string;
  }

  export interface ExternalAuth {
    provider: string;
    idToken: string;
  }

  export interface UploadMedia {
    file: FormData;
  }

  export interface TagApi {
    id: string;
    tagName: string;
  }

  // function to extract excerpt from content
  export const extractExcerpt = (content: string): string => {
    const excerpt = content.slice(0, 30);
    return excerpt.length >= 30 ? excerpt + '...' : excerpt;
  };

  // function to extract text content and remove HTML tags
export const extractTextContent = (content: string): string => {

  const doc = new DOMParser().parseFromString(content, 'text/html');
  return doc.body.textContent || "";
}
  
  export const fetchJournals = async (): Promise<JournalEntry[]> => {
    const response = await fetchAPI('journals?PageSize=50', {}, 'GET');
    const data = response.data;
  
    // Transform data to match JournalEntry structure if needed
    return data.map((item: JournalEntry) => ({
      id: item.id,
      userId: item.userId, 
      title: item.title,
      mood: (!item.mood || (item.mood === "0")) ? 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5d2-fe0f.png' : item.mood,
      isLocked: item.isLocked,
      tags: item.tags.map((tag) => {
        let label, color;
        try {
          const tagData = JSON.parse(tag);
          label = tagData.label;
          color = tagData.color;
        } catch (error) {
          label = tag;
          color = colors[Math.floor(Math.random() * colors.length)];
        }

        return JSON.stringify({
          label: label,
          color: color,
        });
      }),
      entries: item.entries,
      metadata: item.metadata, 
      journalDate: item.journalDate,
      tempId: uuidv4(),
    }));
  };
  

// Authentication Header
export const authHeader = () => {
  const token = Cookies.get('token');

  if (token){
    return { Authorization: 'Bearer ' + token };
  } else {
    return {Authorization: '' };
  }
};

// Current User Details
export const getCurrentUser: any | {} = () => {
  const token = localStorage.getItem('user') || '{}';

  if (token) {
    return JSON.parse(token);
  } else {
    return {};
  }
};

// Get User Profile from API
export const getUserProfile = async (): Promise<any> => {
  const response = await fetchAPI('person/profile', {}, 'GET');

  if(response)
  {
    if(getCurrentUser() !== response.data){
      localStorage.setItem('user', JSON.stringify(response.data));
    }
  }
  // return response;
};

// General API Fetch
// export const fetchAPI = async (path: string, args: any, method='POST', contentType='application/json'): Promise<any|null> => {
//   // console.log("Content Type: "+ args);
//   const response = await fetch(`${API_BASE_URL}/${path}`, {
//     method: method,
//     body: (method !== "GET") ? JSON.stringify(args) : undefined,
//     headers: {
//       'Authorization': authHeader().Authorization,
//       'Content-Type': contentType,
//     },
//   });

//   if(response.status === 201 || response.status === 200 || response.status === 204)
//     console.log(`Status: ${response.status}, Data: ${response.text}`);

//   const data = (response.status === 204) ? { status: true, message: "Successful"} : await response.json();
  

//   // check status code
//   switch (response.status) {
//     case 204:
//     case 200:
//     case 201:
//     case 400:
//     case 401:
//     case 404:
//     case 409:
//     case 422:
//     case 500:
//       return data;
//     default:
//       return null;
//   }
// };

export const fetchAPI = async (path: string, args: any, method = 'POST', contentType = 'application/json'): Promise<any | null> => {
  try {
    const response = await fetch(`${API_BASE_URL}/${path}`,{
      method: method,
      body: ((method !== 'GET') && (method !== 'DELETE')) ? JSON.stringify(args) : undefined,
      headers: {
        'Authorization': authHeader().Authorization,
        'Content-Type': contentType,
      },
    });

    if (response.status === 201 || response.status === 200 || response.status === 204) {
      // console.log(`Status: ${response.status}, Data: ${response}`);
    }

    const data = (response.status === 204) ? { status: true, message: "Successful" } : response.json();

    // check status code
    switch (response.status) {
      case 204:
      case 200:
      case 201:
      case 400:
      case 401:
      case 404:
      case 409:
      case 422:
      case 500:
        return data;
      default:
        return null;
    }
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

export const UploadFormDataAPI = async (path: string, args: any): Promise<any|null> => {

  const response = await fetch(`${API_BASE_URL}/${path}`, {
    method: 'POST',
    body: args,
    headers: {
      'Authorization': authHeader().Authorization,
    },
  });

  const data = await response.json();
  // if(response.status === 201 || response.status === 200)
  //   console.log("Data:", data);

  // check status code
  switch (response.status) {
    case 200:
    case 201:
    case 400:
    case 401:
    case 404:
    case 409:
    case 422:
    case 500:
      return data;
    default:
      return null;
  }
};

// Get Tags from API

// default colors for tags
const colors = ['purple-500',
        'yellow-500',
        'blue-500',
        'red-500',
        'orange-500'];

// {/* fetch tags from API */} 
export const fetchTags = async (): Promise<Tag[]> => {
  const response = await fetchAPI('journals/tags', {}, 'GET');
  const data = response.data;

  console.log('Tags API:', data);
  // filter the name from data tags response which is not JSON parsable to label & color
  return data.map((tag: TagApi) => {
    let label, color;
    try {
      const tagData = JSON.parse(tag.tagName);
      label = tagData.label;
      color = tagData.color;
    } catch (error) {
      label = tag.tagName;
      color = colors[Math.floor(Math.random() * colors.length)];
    }

    return {
      label: label,
      color: color,
    };
  });
}