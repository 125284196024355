import { LucideCalendarDays } from "lucide-react";
import React, { useEffect, useState } from "react";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { JournalEntry } from "utils/api";

const ListEntries:React.FC<{entries: JournalEntry[], onJournalClick?: any, sortByAsc?:boolean}> = ({entries, onJournalClick, sortByAsc}) => {
    

    const [openedYears, setOpenedYears] = useState<{ [key: string]: boolean }>({}); // Tracks open/closed state for each year


    const handleEntryClick = (entry: JournalEntry) => {
        onJournalClick && onJournalClick(entry);
    }

    useEffect(() => {
        // Automatically open all years on component mount
        const initialOpenedYears = entries.reduce((acc: { [key: string]: boolean }, entry) => {
          const year = new Date(entry.journalDate).getFullYear().toString();
          acc[year] = true; // Set all years to true to show them by default
          return acc;
        }, {});
    
        setOpenedYears(initialOpenedYears);
      }, [entries]); 

    let sortedEntries = entries.map(entry => ({
        ...entry,
        tags: [...entry.tags]  // Clone the tags array to avoid tag mutation
      }));
    if (sortByAsc) {
        sortedEntries = sortedEntries.sort((a, b) => new Date(a.journalDate).getTime() - new Date(b.journalDate).getTime());
    } else {
        sortedEntries = sortedEntries.sort((a, b) => new Date(b.journalDate).getTime() - new Date(a.journalDate).getTime());
    }

    // Grouping journals by year
    const groupedEntries = sortedEntries.reduce((acc: {[key: number]: JournalEntry[]}, entry) => {
        const year = new Date(entry.journalDate).getFullYear();
        if (!acc[year]) {
            acc[year] = [];
        }
        acc[year].push(entry);
        return acc;
    }, {});

    const yearGroups = Object.entries(groupedEntries);

    const convertToElipse = (text: string, length=8) => {
        if (text.length > length) {
            return text.substring(0, length) + '.';
        }
        return text;
    }

    // toggle the state for a specific year
    const toggleYear = (year: string) => {
        setOpenedYears(prev => ({
            ...prev, 
            [year]: !prev[year]
        }));
    };

    return (
        <div>
            {yearGroups.map(([year, journal]) => (
                <><div key={year}>
                    {/* Year Header */}
                    <div className="mt-0.5 flex items-center gap-x-2 dark:text-white">
                        <LucideCalendarDays className="w-5 h-5" />
                        <h2 className="text-lg font-semibold leading-6">{year}</h2>
                        {openedYears[year] ? (
                            <BsFillCaretDownFill className="w-3 h-3" onClick={() => toggleYear(year)} />
                        ) : (
                            <BsFillCaretUpFill className="w-3 h-3" onClick={() => toggleYear(year)} />)}
                    </div>

                    {/* Journal Entries for the Year */}

                    {openedYears[year] && (
                        journal.map((entry, index) => (
                            <div key={index}
                                className={`flex md:items-center cursor-pointer hover:bg-bg-primary dark:hover:bg-gray-placeholder justify-between max-md:flex-col max-md:gap-y-2 p-2 mt-3`}
                                onClick={() => handleEntryClick(entry)}
                            >
                                {/* Entry title */}
                                <div className="flex items-center text-[16px] font-medium dark:text-white">
                                    <img src={`${entry.mood}`} alt="Mood" className="w-5 h-5" />
                                    <div className="ml-3">
                                        <p>{entry.title}</p>
                                    </div>
                                </div>

                                {/* Tags and Date */}
                                <div className="flex items-end space-x-4 max-md:space-x-2">
                                    {entry.tags.map((tag, index) => {
                                        let parsedTag;
                                        try {
                                            parsedTag = JSON.parse(tag); //safely parse each tag
                                        } catch (error) {
                                            console.error("Invalid tag JSON: ", tag);
                                            return null; // skip invalid tags
                                        }

                                        return (
                                            <React.Fragment key={`${tag}-${index}`}>
                                                {/* Render for larger screens */}
                                                <span key={`${tag}-${index}`} className={`tag text-xs px-2 py-0.5 max-md:hidden rounded-md bg-${parsedTag.color}`}>
                                                    {parsedTag.label}
                                                </span>
                                                {/* Convert to Elipse ...  for mobile view */}
                                                <span key={`${tag}-${index}`} className={`tag text-xs px-2 py-0.5 md:hidden rounded-md bg-${parsedTag.color}`}>
                                                    {convertToElipse(parsedTag.label, 7)}
                                                </span>
                                            </React.Fragment>
                                        );

                                    })}

                                    {/* Displaying the date */}
                                    <p className="text-body max-md:text-xs">
                                        {new Date(entry.journalDate).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'medium' })}
                                    </p>
                                </div>

                            </div>

                        ))
                    )}
                </div>
                    {/* Display Horizontal Ruler after each Year grouping */}
                    <hr className="text-body my-4" /></>
            ))}
        </div>
    );
};

export default ListEntries;