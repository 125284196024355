import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Typewriter from './TypeWriter';

const Hero = () => {
  const navigate = useNavigate();

  const targetRef = useRef<HTMLDivElement>(null);
  

  return (
    <motion.section
      ref={targetRef}
      style={{
        
      }}
      className="relative bg-bg-200 dark:bg-gray-900 max-md:pt-10 py-24 md:px-32 px-4 h-screen md:h-screen overflow-x-clip scroll-smooth hero-clip w-full"
    >
      <motion.div
        style={{ }}
        className="container mx-auto px-4 flex md:flex-row flex-col items-center max-md:space-y-10 my-4 max-md:mt-0"
      >
        <div className='brand md:w-1/2 w-full'>
          <div className="rounded-full mb-2 z-10 text-primary w-72 text-sm p-1 bg-primary-200">Trusted by 100+ users across the world</div>
          <Typewriter />
          <div className='h-6'></div>
          <p className="mt-4 z-10 text-lg md:text-xl text-black dark:text-white">
          Transform fleeting thoughts into lasting memories with our AI-powered journal, thoughtfully designed to guide you on a journey of self-discovery, personal growth, and well-being.
          </p>
          <button
            onClick={() => navigate('/register')}
            className="mt-8 z-10 text-lg bg-primary text-white px-8 py-4 rounded-lg hover:bg-blue-700 animate-pulse"
          >
            Get started for free
          </button>
        </div>
        <div className='hero-img'>
          <img src='/images/hero/hero-1.svg' alt='Toice' className='max-md:hidden w-1/2 h-2/3 absolute top-10 right-0' />
          <img src='/images/hero/hero-sm-1.svg' alt='Toice' className='md:hidden  h-2/3' />
        </div>

        
      </motion.div>
    </motion.section>
  );
};

export default Hero;
