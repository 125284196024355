import { useTheme } from "context/ThemeContext";
import React from "react";

interface ProContentProps {
  modelState: boolean;
  setModelState: (state: boolean) => void;
}

const ProContent: React.FC<ProContentProps> = ({modelState, setModelState}) => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`${modelState ? 'max-md:h-screen md:h-[29rem] md:overflow-y-auto max-md:overflow-y-hidden max-md:visible':'max-md:hidden'}  max-md:h-[600px]  max-md:px-4 `}>
      <div className="flex max-md:items-start max-md:justify-between md:border-b-2 border-gray-200">
        {!isDarkMode ? 
          <img className=" md:hidden" src="/images/CaretLeft.svg" alt="" onClick={() => setModelState(false)} />
            :
            <img className=" md:hidden" src="/images/darkCaretLeft.svg" alt="" onClick={() => setModelState(false)} />
        }
        <div className="flex items-center gap-x-2 pb-4">
          <h1 className="text-xl font-semibold text-header dark:text-white">Toice</h1>
          <img src="/images/settings/Pro.svg" alt="Toice" />
        </div>
        
        <div></div>
      </div>

      <div className="bg-white dark:bg-bgDark p-4 rounded-lg mx-auto md:max-w-sm">
        <p className="text-center mb-2 text-[16px]">Upgrade to Pro Explore all features. <br/>Start a 3 days free trial now.</p>

        <ul className="list-none flex flex-col pl-2 toice-list gap-y-1 max-md:justify-start max-md:items-start max-md:pl-0 ">
          <li><img src="/images/CheckCircle.svg" alt="" />Text Customization</li>
          <li><img src="/images/CheckCircle.svg" alt="" />Access to interact with an AI chatbot</li>
          <li><img src="/images/CheckCircle.svg" alt="" />Enable customization of backgrounds, themes, and text formatting.</li>
          <li><img src="/images/CheckCircle.svg" alt="" />Show mood trends and progress with visuals.</li>
          <li><img src="/images/CheckCircle.svg" alt="" />Provide privacy settings for each journal entry.</li>
        </ul>

        
      </div>
      <div className=" w-full max-md:w-full p-2 max-md:h-full max-md:mx-0 max-md:px-0">
        <div className="flex justify-between flex-col w-full mx-0 space-y-4 space-x-2">
          <div className="text-center rounded-lg border-[1px] p-2 max-md:p-3 pb-0 border-border-primary w-full">
            <div className="flex flex-1 mr-auto mb-4 justify-between">
              <p>Monthly</p>
              <img src="/images/CheckCircle.svg" alt="" />
            </div>
            <div className="flex flex-1 mr-auto justify-between">
              <p className="text-base font-bold">$2.00</p>
              <p className="text-xs">$1.67/month</p>
            </div>
          </div>
          <div className="text-center rounded-lg border-[1px] p-2 max-md:p-3 pb-0 border-border-primary w-full" style={{ marginLeft: '0' }}>
            <div className="flex flex-1 mr-auto mb-4 justify-between">
              <p>Yearly</p>
              <img src="/images/CheckCircle.svg" alt="" />
            </div>
            <div className="flex flex-1 mr-auto justify-between">
              <p className="text-base font-bold">$20.00</p>
              <p className="text-xs"><del className="text-gray-placeholder">$2.00</del> $1.67/month</p>
            </div>
          </div>

          
        </div>
        <button className="float-right pointer-events-none bg-bg-secondary h-12 hover:bg-blue-700 text-white text-[12px] px-8 py-2 rounded-lg  mx-auto mt-8 max-md:mx-0 max-md:float-none max-md:w-full w-full">
          Subscribe
        </button>
        
      </div>
    </div>
  );
};

export default ProContent;