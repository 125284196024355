import { useAuth } from "context/AuthContext";
import { Navigate, Outlet } from "react-router-dom";


const AuthenticatedRoute = ({redirectPath = '/login'}) => {
    const { authToken } = useAuth();
    // console.log('authToken', authToken);
    return (authToken ? <Outlet /> : <Navigate to={redirectPath} />);
    
};

export default AuthenticatedRoute;


