import { MemoryCarousel } from 'components/landing/Caurosel';
import FaqFeature from 'components/landing/FaqFeature';
import FeaturesSection from 'components/landing/Feature';
import Footer from 'components/landing/Footer';
import Hero from 'components/landing/Hero';
import Navbar from 'components/landing/Navbar';
import React, { lazy, Suspense, useEffect, useRef } from 'react';
const KeyFeature = lazy(() => import("components/landing/KeyFeature"));


const Homepage: React.FC = () => {
    const featureRef = useRef<(HTMLDivElement | null)>(null);
    const faqRef = useRef<(HTMLDivElement | null)>(null);
    const heroVideoRef = useRef<(HTMLDivElement | null)>(null);

    const scrollToHeroVideo = () => {
        if (heroVideoRef.current)
            heroVideoRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    useEffect(() => {
        scrollToHeroVideo();
    }, []);
    return (
        <div>

            <Navbar featureRef={featureRef} faqRef={faqRef} />

            <section className='hero-wrapper'>
                <Hero />
            </section>

            <FeaturesSection />

            <Suspense fallback={<div>Loading...</div>}>
                <KeyFeature featureRef={featureRef} />
            </Suspense>
            <MemoryCarousel />
            <FaqFeature faqRef={faqRef} />
            <Footer featureRef={featureRef} faqRef={faqRef} />
        </div>

    );
};

export default Homepage;
