import { useAuth } from "context/AuthContext";
import React from "react";
import { useNavigate } from "react-router-dom";

const LogoutPage: React.FC = () => {

    const navigate = useNavigate();
    const { authToken, logout:authLogout } = useAuth();
    
    if(authToken)
    {
        authLogout();
    }

    setTimeout(() => {
        navigate('/login', { replace: true });
    }, 500);


    return (
        <>
            <h2>Logging out...</h2>
        </>
    );
};

export default LogoutPage;