import { API_BASE_URL, LoginEntry, BaseResponse } from './api';

export const fetchLogin = async (loginData: LoginEntry): Promise<BaseResponse|null> => {
  const response = await fetch(`${API_BASE_URL}/auth/signin`, {
    method: 'POST',
    body: JSON.stringify(loginData),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',

    },
  });

  const data = await response.json();

  // check status code
    switch (response.status) {
        case 200:
            localStorage.setItem('user', JSON.stringify(data.data.user));
            // localStorage.setItem('token', data.data.accessToken);
            return data;
        case 400:
        case 404:
        case 422:
        case 500:
            return data;
        default:
            return null;
    }
};

export const logout = () => {
  // remove the token from storage
  localStorage.removeItem("token");
  localStorage.removeItem("user");
}

// export const getCurrentUser = () => {
//   const token = localStorage.getItem("token");

//   if(token)
//   {
    
//   }
// }