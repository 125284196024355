import React from 'react'
import { ToastProvider } from '../context/ToastContext';
import Register from '../components/auth/Register';
import VerifyOtp from '../components/auth/VerifyOtp';

export const RegisterPage: React.FC = () => {
    return (
        <ToastProvider>
            <Register />
        </ToastProvider>
    );
}

export const VerifyEmailPage: React.FC = () => {
    return (
        <ToastProvider>
            <VerifyOtp />
        </ToastProvider>
    );
}