import { Editor, NodeViewWrapper } from '@tiptap/react'
import { useCallback } from 'react'

import { ImageUploader } from './ImageUploader'

export const ImageUpload = ({ getPos, editor }: { getPos: () => number; editor: Editor }) => {
  const onUpload = useCallback(
    (url: string) => {
      if (url) {
        const transaction = editor.state.tr
        // Remove the current node (ImageUpload component) and insert the image
        .replaceWith(getPos(), getPos()+1, editor.schema.nodes.imageBlock.create({ src: url}));
        // editor.chain().focus().setImageBlock({src: url }).focus().run()

        editor.view.dispatch(transaction); // Dispatch the transaction to update the editor content
        // Focus back to the editor and update selection if necessary
        editor.chain().focus().run();
      }
    },
    [getPos, editor],
  )

  return (
    <NodeViewWrapper>
      <div className="p-0 m-0" data-drag-handle>
        <ImageUploader onUpload={onUpload} />
      </div>
    </NodeViewWrapper>
  )
}

export default ImageUpload
