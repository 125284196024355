import React, { useEffect, useState } from "react";
import ProfileContent from "./content/ProfileContent";
import ProContent from "./content/ProContent";
import { FaSignOutAlt } from "react-icons/fa";
import PasswordContent from "./content/PasswordContent";
import { Link } from "react-router-dom";
import PersonalizationContent from "./content/PersonalizationContent";
import ExportContent from "./content/ExportContent";
import { getCurrentUser } from "utils/api";
import { useTheme } from "context/ThemeContext";

interface SettingsMenuProps {
    closeModal: () => void;
    generalMenu: (menu: string|null) => void;
}

const SettingsMenu:React.FC<SettingsMenuProps> = ({closeModal, generalMenu}) => {
    const user = getCurrentUser();
    const dateJoined = new Date(user.createdAtUtc).toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' });
    const [selectedMenu, setSelectedMenu] = useState<string>('profile');
    const [isItemOpen, setIsItemOpen] = useState<boolean>(false);
    const { isDarkMode } = useTheme();

    useEffect(() =>{
        if(!isItemOpen)
            generalMenu(null);
    }, [generalMenu, isItemOpen])
    const renderContent = () => {
        
        switch(selectedMenu) {
            case 'profile':
                generalMenu(null);
                return <ProfileContent modelState={isItemOpen} setModelState={setIsItemOpen} />;
            case 'toice':
                generalMenu(isItemOpen ? 'toice': null);
                return <ProContent modelState={isItemOpen} setModelState={setIsItemOpen} />;
            case 'personalization':
                generalMenu(isItemOpen ? 'personalization': null);
                return <PersonalizationContent modelState={isItemOpen} setModelState={setIsItemOpen} />;
            case 'export':
                generalMenu(isItemOpen ? 'export': null);
                return <ExportContent modelState={isItemOpen} setModelState={setIsItemOpen} />;
            case 'password':
                generalMenu(isItemOpen ? 'password': null);
                return <PasswordContent defaultOption="system" modelState={isItemOpen} setModelState={setIsItemOpen} />;
            default:
                generalMenu(null);
                return <ProfileContent modelState={isItemOpen} setModelState={setIsItemOpen} />;
        }
    };

    const styleList = `text-primary dark:text-white font-semibold border-l-2 border-highlight pl-2 max-md:border-l-0`;

    return (
        <div className={`${isDarkMode ? 'dark' : ''} flex max-md:flex-col rounded-lg max-md:px-0 md:h-auto md:w-full max-md:pt-4 dark:bg-bgDark dark:text-white`}>
            <div className={`flex max-md:items-start max-md:justify-between pb-1 mb-3 md:hidden ${isItemOpen ? 'max-md:hidden':'max-md:visible'} max-md:px-4`}>
                {!isDarkMode ? 
                    <img className=" md:hidden" src="/images/CaretLeft.svg" alt="" onClick={closeModal} />
                    :
                    <img className=" md:hidden" alt="CaretLeft" src="/images/darkCaretLeft.svg" onClick={closeModal} />
                }
                <h2 className="text-xl font-semibold text-header dark:text-white">Settings</h2>
                <div></div>
            </div>
            {/* Sidebar Settings List */}
            <div className={`bg-white dark:bg-bgDark w-64 max-md:w-full md:h-full h-[617px] max-md:my-0 max-md:mx-[14px] max-md:p-0 max-md:h-full p-6 md:p-2 md:border-r max-md:border-spacing-1 max-md:mt-4 ${isItemOpen ? 'max-md:hidden':'max-md:visible'}`}>
                <ul className="space-y-4 setting-menu max-md:text-xl max-md:px-[14px]">
                    <li className={`flex items-center cursor-pointer ${selectedMenu === 'profile' ? styleList : 'text-body dark:text-white'}`} onClick={() => { setSelectedMenu('profile'); setIsItemOpen(true); }}>
                        {!isDarkMode ? 
                            <img src="/images/settings/UserCircle.svg" alt="UserCircle" />
                            :
                            <img src="/images/settings/darkUserCircle.svg" alt="UserCircle" />
                        }
                        Account
                        <img className="ml-auto md:hidden" src="/images/settings/CaretRight.svg" alt="Right" />
                    </li>
                    {/* <li className={`flex items-center cursor-pointer ${selectedMenu === 'toice' ? styleList : 'text-body dark:text-white'}`} onClick={() => {setSelectedMenu('toice'); setIsItemOpen(true);}}>
                        {!isDarkMode ? 
                            <img src="/images/settings/Crown.svg" alt="Account" />
                            :
                            <img src="/images/settings/darkCrown.svg" alt="Account" />
                        }
                        <div className="flex gap-2 items-center">
                            Toice
                            <img src="/images/settings/Pro.svg" alt="Toice" />
                        </div>
                        <img className="ml-auto md:hidden" src="/images/settings/CaretRight.svg" alt="Right" />
                    </li> */}
                    <li className={`flex items-center cursor-pointer ${selectedMenu === 'personalization' ? styleList : 'text-body dark:text-white'}`} onClick={() => {setSelectedMenu('personalization'); setIsItemOpen(true);}}>
                        {!isDarkMode ? 
                            <img src="/images/settings/PaintRoller.svg" alt="PaintRoller" />
                            :
                            <img src="/images/settings/darkPaintRoller.svg" alt="PaintRoller" />
                        }
                        Personalization
                        <img className="ml-auto md:hidden" src="/images/settings/CaretRight.svg" alt="Right" />
                    </li>
                    
                    <li className={`flex items-center cursor-pointer ${selectedMenu === 'password' ? styleList : 'text-body dark:text-white'}`} onClick={() => {setSelectedMenu('password'); setIsItemOpen(true);}}>
                        {!isDarkMode ? 
                            <img src="/images/settings/LockKey.svg" alt="Lock" />
                            :
                            <img src="/images/settings/darkLockKey.svg" alt="Lock" />
                        }
                        Security
                        <img className="ml-auto md:hidden" src="/images/settings/CaretRight.svg" alt="Right" />
                    </li>

                    <li className={`flex items-center cursor-pointer ${selectedMenu === 'export' ? styleList : 'text-body dark:text-white'}`} onClick={() => {setSelectedMenu('export'); setIsItemOpen(true);}}>
                        {!isDarkMode ? 
                            <img src="/images/settings/Export.svg" alt="Export" />
                            :
                            <img src="/images/settings/darkExport.svg" alt="Export" />
                        }
                        Export
                        <img className="ml-auto md:hidden" src="/images/settings/CaretRight.svg" alt="Right" />
                    </li>
                   
                </ul>
            </div>

            {/* Content Menu  */}
            <div className={`${isDarkMode ? 'dark' : ''} md:p-4 md:ml-4 flex bg-white dark:bg-bgDark  flex-col w-full`}>
                {renderContent()}
                {selectedMenu === 'profile' && 
                    <>
                        {isItemOpen ? <div className="flex items-center mt-20 ml-auto mr-auto flex-col">
                                <Link to={'/logout'}
                                    className="flex items-center text-red-600 hover:text-red-700 mb-4">
                                        <FaSignOutAlt className="mr-2" /> Logout
                                </Link>
                                <span className="text-xs text-[#B5B5B5]">Member since {dateJoined}</span>
                            </div> : <div className="flex items-center max-md:hidden mt-auto ml-auto mr-auto flex-col ">
                            <Link to={'/logout'}
                                className="flex items-center text-red-600 hover:text-red-700 mb-4">
                                    <FaSignOutAlt className="mr-2" /> Logout
                            </Link>
                            <span className="text-xs text-[#B5B5B5]">Member since {dateJoined}</span>
                        </div>
                        }
                        
                    </>
                }
            </div>
        </div>
    );
};

export default SettingsMenu;