import React from 'react';
import { CiSearch } from 'react-icons/ci';

const SearchBar: React.FC = () => {
  return (
    <div className="flex items-center bg-[#DEE8F7] p-2 rounded-lg max-md:hidden" style={{ width: '437px', height: '40px' }}>
      <CiSearch className="text-gray-500 ml-4" style={{ width: '18px', height: '18px', top: '1.49px', left: '1.49px' }} />
      <input
        type="text"
        placeholder="Search"
        className="bg-[#DEE8F7] ml-2 flex-1 border-none outline-none text-gray-500 placeholder-gray-500"
      />
    </div>
  );
};

export default SearchBar;
