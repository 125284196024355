import React, { useEffect, useRef, useState } from "react";
import { getCurrentUser, getUserProfile, UploadMedia } from "../../../utils/api";
import { useMediaUpload } from "../../../hooks/useMediaUpload";
import { apiProps, UpdateProfile, useApi } from "../../../hooks/useApi";
import { Spinner } from "../../../tiptap/ui/Spinner";
import Alert from "components/Alert";
import { useTheme } from "context/ThemeContext";

interface ProfileContentProps {
  modelState: boolean;
  setModelState: (state: boolean) => void;
}

const ProfileContent: React.FC<ProfileContentProps> = ({modelState, setModelState}) => {
  const user = getCurrentUser();
  const [imageSrc, setImageSrc] = useState<string>(user.profileUrl);
  const [uploadTrigger, setUploadTrigger] = useState<UploadMedia | null>(null);
  const {loading, baseResponse} = useMediaUpload(uploadTrigger);
  const [name, setName] = useState<string>(user.name);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [ updateTrigger, setUpdateTrigger ] = useState<apiProps>({ data: null, method: 'POST', endpoint:''});
  const { loading:updateLoading, baseResponse:updateResponse } = useApi(updateTrigger)
  const [success, setSuccess] = useState<string | null>(null);
  const [isError, setError] = useState<string | null>(null);
  const { isDarkMode } = useTheme();

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if(file) {
      const formData = new FormData();
      formData.append('formCollection', file);

      setUploadTrigger({file: formData});
    }
  };

  // handle submit
  const handleSubmit = (e: React.FormEvent) => {
    // handle submit
    e.preventDefault();

    const data: UpdateProfile = {name: name, profileUrl: imageSrc};
    // console.log('Name: ', {name: name, profileUrl: imageSrc});
    setUpdateTrigger({data: data, method: 'POST', endpoint: 'person/profile/update'});

  }

  useEffect(() => {
    if(baseResponse){

        if(baseResponse.status) {
          setSuccess('Image uploaded successfully');
          // toast.success('Image uploaded successfully', NotifyOptions);
          setImageSrc(baseResponse.message);
        }else {
          setError(baseResponse.message);
          // toast.error(baseResponse.message, NotifyErrorOptions);
        }
      setUploadTrigger(null);  
  
        // console.log('Image Upload response:', baseResponse?.message);
    }
    
  },[baseResponse]);

  useEffect(() => {
    async function fetchData() {
      if(updateResponse){
        if(updateResponse.status) {
          // toast.success('Profile updated successfully', NotifyOptions);
          setSuccess('Profile updated successfully');
          await getUserProfile()
          // console.log('Update response:', updateResponse);
        } else {
          setError(updateResponse.message);
          // toast.error(updateResponse.message, NotifyErrorOptions);
        }
      }
    }
    fetchData();
  },[updateResponse]);

  const handleErrorDismiss = () => {
    setError(null);
  }

  const handleSuccessDismiss = () => {
    setSuccess(null);
  }

  return (
    <div className={`${modelState ? 'max-md:visible':'max-md:hidden'} max-md:px-4 md:h-[20rem] md:overflow-y-auto max-md:pr-0`}>
      <div className="flex max-md:items-start max-md:justify-between md:border-b-2 border-gray-200 ">
        {!isDarkMode ? 
          <img className="md:hidden" src="/images/CaretLeft.svg" alt="" onClick={() => setModelState(false)} />
            :
          <img className="md:hidden" src="/images/darkCaretLeft.svg" alt="" onClick={() => setModelState(false)} />
        }
        <h1 className="text-xl font-semibold text-header dark:text-white pb-4">Account</h1>
        <div></div>
      </div>

      <div className="flex flex-col mt-2 max-md:pl-2">
        {/* Alert */}
        {/* Danger Alert */}
        { isError &&
            <Alert message={isError} dismissTime={4000} onDismiss={handleErrorDismiss} />
        }

        { success && 
            <Alert type="success" message={success} dismissTime={3000} onDismiss={handleSuccessDismiss} />
        }
        {/* Alerts ends here */}
        <div className="flex items-center max-md:justify-left space-x-3 py-4">
          
          <img src={imageSrc}
             alt="User Profile" 
              onClick={handleImageClick}
             className={`w-16 h-16 max-md:w-[5rem] max-md:h-[5rem] rounded-full object-contain relative ${loading ? 'blur-sm' : ''}`} />
          <input type="file" style={{display: 'none' }} 
            ref={fileInputRef}
            onChange={handleFileChange}
            />
          
          <button className="bg-white dark:bg-bgDark text-primary font-bold" onClick={handleImageClick}>Edit</button>

          {loading && <Spinner className="text-primary absolute left-11" size={1.5} />}

        </div>
        <div className="mt-4 w-full">
          <input type="text" placeholder="Name" className="w-full border-2 border-gray-200 p-3 rounded-lg text-header text-sm" value={name}
            onChange={(e) => setName(e.target.value)} />
        </div>

        {/* Save button on the left side */}
        
        <div className="mt-4 flex justify-end max-md:justify-center max-md:mt-10">
          <button onClick={handleSubmit} className=" bg-primary text-white p-3 w-[200px] max-md:w-full rounded-lg disabled:bg-body cursor-pointer disabled:cursor-not-allowed" disabled={updateLoading}>Save Changes</button>
        </div>
      </div>
      
    </div>
  );
};

export default ProfileContent;