import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import { BubbleMenu, Editor, EditorContent, useEditor } from "@tiptap/react";
import React, { useEffect, useState } from "react";
import Italic from "@tiptap/extension-italic";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import Placeholder from "@tiptap/extension-placeholder";
import Heading from "@tiptap/extension-heading";
import StarterKit from "@tiptap/starter-kit";
import { fetchAPI, JournalEntry } from "../utils/api";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { BubbleMenuButton }  from "./BubbleMenuButton";
import { CommandPlugins } from "./CommandMenu";
import { Emoji, emojiSuggestion, FileHandler, Focus, Highlight, ImageBlock, Link, Selection, TrailingNode } from "./extensions";
import API from "./lib/api";
import { ContentItemMenu } from "./components/menus";
import SynthWriter from "./SynthAiWriter";
import { ReactComponentNode } from "./ReactComponentNode";
import MyLoadingComponent from "components/journal/AiLoading";
import Image from "@tiptap/extension-image";
import ImageResize from 'tiptap-extension-resize-image';
import CustomUploader from "./CustomUploader";
import { AiResponse } from "./AiResponseNode";
import { gitHubEmojis } from '@tiptap-pro/extension-emoji';
import { Indent } from "./extensions/Indent";
import UserParagraph from "./UserParagraph";
import AIDialog from "./SynthAiDialog";
interface AiData {
    apiEndpoint: string;
    bodyContent: string;
    user: {
        aiName: string;
    };
    editor: Editor;
}

interface TipTapProps {
    onContentChange?: (content: string) => void;
    journal? : JournalEntry;
    isEditing?: boolean;
}

// custom Emoji for Toice AI
const customEmojis = [
  {
    // // A unique name of the emoji which will be stored as attribute
    name: 'shootingStar',
    // A list of unique shortcodes that are used by input rules to find the emoji
    shortcodes: ['shootingstar'],
    // A list of tags that can help for searching emojis
    tags: ['star', 'shoot', 'toice', 'ai'],
    // A name that can help to group emojis
    group: 'Toice custom group of emojis',
    // The image to be rendered
    fallbackImage: '/images/shootingstar.svg',
  }
]

const extensions = [
    Document, UserParagraph, 
    Text, Bold, Italic,
    BulletList, OrderedList, ListItem,
    TextAlign.extend({
        addKeyboardShortcuts() {
          return {}
        },
      }).configure({
        types: ['heading', 'paragraph'],
        alignments: ['left', 'center', 'right'],
    }), 
    Underline,
    Placeholder.configure({
        placeholder: 'Write something or type "/" for commands',
        emptyEditorClass: 'is-editor-empty',
    }),
    Heading, StarterKit,
    Emoji.configure({
        emojis: [...gitHubEmojis, ...customEmojis],
        enableEmoticons: true,
        suggestion: emojiSuggestion,
        forceFallbackImages: true
      }),
      Link.configure({
        openOnClick: false,
      }),
      Highlight.configure({ multicolor: true }),
      ImageBlock,
      Image.configure({
        inline: true,
        HTMLAttributes: {
          class: 'w-full h-auto',
        },
        allowBase64: true,
      }),
      FileHandler.configure({
        allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
        onDrop: (currentEditor, files, pos) => {
          files.forEach(async file => {
            const url = await API.uploadImage(file)
    
            currentEditor.chain().focus().setImageBlockAt({ pos, src: url }).focus().run()
          })
        },
        onPaste: (currentEditor, files) => {
          files.forEach(async file => {
            const url = await API.uploadImage(file)
    
            return currentEditor
              .chain()
              .focus()
              .setImageBlockAt({ pos: currentEditor.state.selection.anchor, src: url })
              .focus()
              .run()
          })
        },
      }),
      Selection, TrailingNode,
      Focus,
      CommandPlugins,
      ImageResize,
      ReactComponentNode.configure({
        ReactComponent: MyLoadingComponent,
      }),
      AiResponse,
      CustomUploader,
      Indent.configure({
        types: ['listItem', 'paragraph'],
        minLevel: 0,
        maxLevel: 8,
    })

];


export const TipTapEditor: React.FC<TipTapProps> = ({onContentChange, journal, isEditing}) => {
    // const [content, setContent] = useState<string>('<p></p>');
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [aiContent, setAiContent] = useState(''); // AI content to display in the dialog
  
    const [aiData, setAiData] = useState<AiData | null>(null); // Holds context for the AI command

    
    const editor = useEditor({
        extensions: [...extensions, SynthWriter.configure({
          apiEndpoint: 'ai/synth/generate',
          onAskAI: (data: any) => {
            setAiData(data); // Set the data for the AI dialog
            setShowDialog(true); // Show dialog
            generateAIContent(data); // Start AI content generation immediately
        },
        }),],
        autofocus: true,
        editorProps: {
          attributes: {
            class: "w-full prose [&_ol]:list-decimal [&_ul]:list-disc h-full text-wrap focus:outline-none outline-none mb-2 max-md:text-sm max-md:leading-6 max-md:text-black dark:text-white",
            style: "line-height: 1.6; white-space: pre-line",
          },
        },
        onUpdate({ editor }) {
          // console.log("Editor content updated: ", editor.getHTML());
            // set the content changes to the editor content
            onContentChange && onContentChange(editor.getHTML());
        }
    });



    useEffect(() => {
        if (journal && editor) {
            // Set the editor content only if the journal prop changes
              // editor.commands.setContent(journal.entries[0]?.content || '<p></p>');
              // 
              const currentContent = editor.getHTML();
              if(currentContent !== journal.entries[0]?.content)
              {
                // Before updating the content, the current cursor position is saved
                const {from, to} = editor.state.selection;
                // After setting the new content with
                editor.commands.setContent(journal.entries[0]?.content || '<p></p>');
                // the cursor position is restored using
                editor.commands.setTextSelection({ from, to });
              }
        }
    
    }, [journal, editor]);

    useEffect(() => {
        // Clean up editor instance when the component unmounts
        return () => {
            if (editor) {
              editor.destroy();
            }
        };
    }, [editor]);

    // Function to generate AI content
    const generateAIContent = async (data: AiData) => {
      setLoading(true);
      try {
          // get current journal id
          const journalId = journal?.id;
          const response = await fetchAPI(data.apiEndpoint, { content: data.bodyContent, journalID: journalId }, 'POST', 'application/json');
          setAiContent(response.data || 'Failed to generate AI content');
      } catch (error) {
          console.error('Error generating AI content:', error);
          setAiContent('Error generating AI content. Please try again.');
      } finally {
          setLoading(false);
      }
  };

  // Function to handle accepting AI content
  const handleAcceptAI = () => {
    if (aiData && aiContent) {
      // Modify the content to include the AI name
      let content = `<p><span data-type="emoji" data-name="shootingstar"></span> <strong data-type='ai_name'>${aiData.user.aiName}</strong></p>${aiContent}`;
    
      // Remove any empty paragraphs
      content = content.replace(/<p><\/p>/g, '');
    
      // Split the content into paragraphs and map to add data-type attribute
      let newContent = content.split(/<p>/g)
        .map(paragraph => `<p data-type="ai">${paragraph.trim()}`)
        .filter(paragraph => (paragraph.trim() !== '<p></p>' || paragraph.trim() !== '<p data-type="ai"></p>'));
    
      // console.log(newContent);
    
      // Start chaining commands
      aiData.editor.chain().focus()
        .deleteNode('paragraph') // Delete the current paragraph
        .setHorizontalRule() // Add a horizontal rule before inserting new content
        .insertContent(newContent.slice(1).join('')) // Insert the new content
        .setHorizontalRule() // Add a horizontal rule after inserting new content
        .run(); // Finalize the chain
  
    }
    
      setShowDialog(false); // Close the dialog
  };

  // Function to handle declining AI content
  const handleDeclineAI = () => {
      setShowDialog(false); // Close dialog without inserting AI content
  };

  // Function to handle regenerating AI content
  const handleRegenerateAI = () => {
      if (aiData) {
          generateAIContent(aiData); // Re-trigger the AI generation
      }
  };

    return (
        <>
            {editor && (
                <>
                    {/*  */}
                    <ContentItemMenu editor={editor} />
                    <BubbleMenu editor={editor} tippyOptions={{duration: 100}}>
                        <BubbleMenuButton editor={editor} />
                    </BubbleMenu>
                    
                </>
            )}
            
            <EditorContent editor={editor} style={{whiteSpace: "pre-line"}} />

            {/* AI Dialog */}
            {showDialog && (
                <AIDialog
                    onAccept={handleAcceptAI}
                    onDecline={handleDeclineAI}
                    onRegenerate={handleRegenerateAI}
                    loading={loading}
                    aiContent={aiContent}
                />
            )}
        </>
    );
};