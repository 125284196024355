import React, { useEffect, useRef, useState } from 'react';
import ListEntries from './ListEntries';
import { Entry, getCurrentUser, JournalEntry } from 'utils/api';
import { v4 as uuidv4 } from 'uuid';
import { TipTapEditor } from '../../tiptap/TipTapEditor';
import { apiProps, useApi } from 'hooks/useApi';
import { format } from 'date-fns/format';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { NotifyErrorOptions, NotifyOptions, Tag } from 'utils/helper';
import { useNavigate } from 'react-router-dom';
import TagSelector from './TagSelector';
import { FaAngleDown, FaTag, FaXmark } from 'react-icons/fa6';
import { LucideCalendarDays, LucideCloudOff, LucideCloudUpload } from 'lucide-react';
import { useJournalList } from 'context/ListContext';
import { PiSortAscending } from 'react-icons/pi';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import 'react-datepicker/dist/react-datepicker.css';
import { Spinner } from "../../tiptap/ui/Spinner";
import { useTheme } from 'context/ThemeContext';
import Picker from "emoji-picker-react";
interface JournalAppProps {
  toggleFullScreen: boolean;
  editorOpen: boolean;
  setEditorOpen: (state: boolean) => void;
}

const JournalApp: React.FC<JournalAppProps> = ({toggleFullScreen, editorOpen, setEditorOpen}) => {
  const defaultEntry: JournalEntry = {
      title: '',
      mood: '',
      isLocked: false,
      tags: [],
      entries: [],
      journalDate: new Date().toISOString()
  };

  const [selectedYear, setSelectedYear] = useState<string|null>(null);
  const [isOpened, setIsOpened] = useState(true);

  const [isMoodPickerOpen, setIsMoodPickerOpen] = useState(false);

  const _currentUser = getCurrentUser(); // get current user from localStorage

  const [currentJournal, setCurrentJournal] = useState<JournalEntry>(defaultEntry);
  const [_tempJournal, setTempJournal] = useState<JournalEntry | null>(null);
  
  const [viewAllEntries, setViewAllEntries] = useState(true);
  const [isDropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [ deleteTrigger, setDeleteTrigger ] = useState<apiProps>({ data: null, method: 'DELETE', endpoint:''});
  const { baseResponse:deleteResponse } = useApi(deleteTrigger)
  const [ deleteJournal, setDeleteJournal ] = useState<JournalEntry | null>(null);

  const [defaultDate] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(defaultDate);

  const navigate = useNavigate();
  const {isDarkMode} = useTheme();


  const {journals:entries, addToJournals, newJournal, isSaving, setNewJournal, isFetching:loadJournals, retriggerFetch} = useJournalList();
  const [sortAsc, setSortAsc] = useState<boolean>(false);

  let sortedEntries = entries.map(entry => ({
    ...entry,
    tags: [...entry.tags]  // Clone the tags array to avoid tag mutation
  }));
  if (sortAsc) {
      sortedEntries = sortedEntries.sort((a, b) => new Date(a.journalDate).getTime() - new Date(b.journalDate).getTime());
  } else {
      sortedEntries = sortedEntries.sort((a, b) => new Date(b.journalDate).getTime() - new Date(a.journalDate).getTime());
  }

  const groupedEntries = sortedEntries.reduce((acc: {[key: number]: JournalEntry[]}, entry) => {
      const year = new Date(entry.journalDate).getFullYear();
      if (!acc[year]) {
          acc[year] = [];
      }
      acc[year].push({...entry});
      return acc;
  }, {});

  const yearGroups = Object.entries(groupedEntries);

  const newEntry: Entry = {
    entryType: 'Text',
    content: ''
  };

  const onTagChange = (selectedTags: Tag[]) => {
    
    const updatedTags = selectedTags.map((tag: Tag) => JSON.stringify(tag));
    
    const updatedJournal = { ...currentJournal, tags: updatedTags };
    // console.log("Selected Tags: ",updatedJournal);
    setCurrentJournal(updatedJournal);

  };

  const handleNewEntry = () => {
  
    const newJournal: JournalEntry = {
      tempId: uuidv4(),
      title: 'Untitled',
      mood: 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f5d2-fe0f.png',
      isLocked: false,
      tags: [],
      entries: [
        newEntry
      ],
      journalDate: format(defaultDate, 'yyyy-MM-dd HH:mm:ss.SSS')
    };

    setCurrentJournal(newJournal);
    setTempJournal(newJournal); // set the temp journal for comparison
    setViewAllEntries(false);
    setEditorOpen(true);
  };

  const handleTitleChange = (event: { target: { value: any; }; }) => {
    const updatedTitle = event.target.value;
    
    const updatedJournal = { ...currentJournal, title: updatedTitle };

    setCurrentJournal(updatedJournal);
    
  };

  // Handle Mood Emoji change
  const handleMoodChange = (event: any, emojiObject: { target: any; }) => {

    const updatedJournal = { ...currentJournal, mood: emojiObject.target.src };
    setCurrentJournal(updatedJournal);
    setIsMoodPickerOpen(false);
  }

  const handleEditorContent = (content: string) => {
    
    const updatedEntries = currentJournal.entries.map((entry, index) =>
      index === 0 ? { ...entry, content } : entry
    );
    
    const updatedJournal = { ...currentJournal, entries: updatedEntries };
    
    // update current Journal
    setCurrentJournal(updatedJournal);
  }

  const handleViewAllEntries = () => {
    setEditorOpen(false);
    retriggerFetch();
    setCurrentJournal(defaultEntry);
    setTempJournal(defaultEntry);
    setSelectedDate(defaultDate);
    setViewAllEntries(true);
    
    
  };

  const handleJournalClick = (journal: JournalEntry) => {
    setCurrentJournal(journal);
    setSelectedDate(new Date(journal.journalDate));
    setTempJournal(journal); // set the temp journal for comparison
    setViewAllEntries(false);
    setEditorOpen(true);
  }

  // handle delete operation from the list of entries and from the API
  const handleDeleteEntry = (journal: JournalEntry) => {
    // delete from the API
    if(journal.id)
    {
      setDeleteJournal(journal);
      setDeleteTrigger({ data: {}, method: 'DELETE', endpoint:`journals/${journal.id}`})
      setDropdownVisible(false);
    }
  }

  const handleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  /**
   * Handles the click outside of the dropdown component.
   * If the click is outside the dropdown, it sets the dropdown visibility to false.
   * 
   * @param event - The MouseEvent object representing the click event.
   */
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isSaving) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    if (isSaving) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSaving]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  // save entries to API but wait some interval due to constant keystroke when entries change
  useEffect(() => {
    if((currentJournal.id || currentJournal.tempId) && _tempJournal !== currentJournal){
      const timeout = debounce(async () => {
        await addToJournals(currentJournal); // add or update journal entry to API
        setTempJournal(currentJournal); // set the temp journal for comparison
      }, 2000);

      timeout();

      return () => {
        timeout.cancel();
      };
    }

  }, [currentJournal]);


useEffect(() => {
  if(newJournal)
  {
    // check if the current journal is a new entry
    if(currentJournal.tempId === newJournal.tempId)
    {
      // update the current journal with the new journal
      var updatedJournal = {...currentJournal, id: newJournal.id, tempId: newJournal.tempId};
      setCurrentJournal(updatedJournal);
    }
  }

  return () => {
    // reset the new journal state
    setNewJournal(null);
  };
}, [newJournal]);


useEffect(() => {
  if (deleteResponse && deleteResponse.status) {
    const updatedEntries = entries.filter((entry) => entry.id !== deleteJournal?.id);

    if (updatedEntries.length === 0) {
      // reload the page async to get the updated list of entries
      navigate(0);
    } else {

      // If the current journal was deleted, reset to another entry or default
      if (currentJournal.id === deleteJournal?.id) {
        setCurrentJournal(updatedEntries[0] || defaultEntry);
      }
    }

    toast.info('Entry deleted successfully', NotifyOptions);
    handleViewAllEntries();

  } else if (deleteResponse && !deleteResponse.status) {
    toast.error(deleteResponse.message, NotifyErrorOptions);
  }

  return () => {
    setDeleteTrigger({ data: null, method: 'DELETE', endpoint: '' });
  };

}, [deleteResponse]);

  return (
    <div className={`flex flex-auto justify-evenly bg-white dark:bg-bgDark md:gap-x-4 px-1 h-auto md:pt-4  md:overflow-hidden journal-app-parent`}>
      { toggleFullScreen && editorOpen ? <></> :
        (<div className={`journal-app flex flex-col h-screen bg-white dark:bg-bgDark ${editorOpen ? 'md:max-w-[40%] md:pl-10 max-md:hidden':'md:px-[10%]'}`}>
          <div className='w-full'>
            <p className="text-3xl ml-0 text-header dark:text-white font-bold max-md:hidden">{(_currentUser.name === '' ? 'User': _currentUser.name) || 'User'}'s Journal </p>
            <p className='w-full p-2 px-0 text-body text-wrap text-left'>Your journal is a safe space for your thoughts, dreams, and reflections. It's where you can be yourself, capture your moments, and explore your ideas. Alongside your writing, you can chat with an AI companion, always ready to listen, offer insights, and help you on your journey of self-discovery.</p>
          </div>
          <div className="top-nav flex items-center justify-between p-2 px-0 text-highlight border-b">
            <h2 className="text-lg max-md:text-[16px] font-medium cursor-pointer" onClick={handleViewAllEntries}>
                All Entries ({entries.length})
            </h2>
            <div className="flex items-center gap-x-5">
              {isSaving ? <LucideCloudUpload className="w-8 h-8 dark:text-white" /> : <LucideCloudOff className="w-8 h-8 dark:text-white" /> }
              {/* Sort Ascending order, toggle the color if selected or not */}
              <PiSortAscending className={`w-10 h-10 cursor-pointer text-header dark:text-white ${sortAsc ? 'text-highlight': ''}`} onClick={() => setSortAsc(!sortAsc)} />
              <button onClick={handleNewEntry} className="new-entry-btn p-2 w-full bg-highlight text-white rounded">New entry</button>
            </div>
            
          </div>
          
          <div className="flex flex-1">
            
            {!viewAllEntries && <div className="sidebar w-full p-4 pl-0 bg-white md:overflow-x-hidden md:overflow-y-auto dark:bg-bgDark h-[55vh]">
            
              {yearGroups.map(([year, journal]) => (
                <><div key={year}>
                  <div className="mt-0.5 flex items-center gap-x-2 dark:text-white">
                    <LucideCalendarDays className="w-5 h-5" />
                    <h2 className="text-lg font-semibold leading-6">{year}</h2>
                    {isOpened && <BsFillCaretDownFill className="w-3 h-3" onClick={() => { setSelectedYear(year); setIsOpened(!isOpened); } } />}
                    {!isOpened && <BsFillCaretUpFill className="w-3 h-3" onClick={() => { setSelectedYear(year); setIsOpened(!isOpened); } } />}
                  </div>

                  {journal.map((entry, index) => (
                    <div key={index} className={`entry p-2 mt-3 ${(currentJournal.tempId === entry.tempId || (entry.id && currentJournal.id === entry.id)) ? ' bg-bg-primary dark:bg-bg-secondary rounded-md' : ''} ${selectedYear === year ? (!isOpened ? 'hidden transition-transform ease-in-out' : 'transition-transform ease-in-out') : ''} hover:bg-bg-primary hover:cursor-pointer`}
                      onClick={() => handleJournalClick(entry)}>
                      <div className="flex items-center text-sm font-medium text-body dark:text-white">
                        <img src={`${entry.mood}`} alt='Mood' className="w-5 h-5" />
                        <div className="ml-3">
                          <p>{entry.title}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                  {/* Display Horizontal Ruler after each Year grouping */}
                  <hr className="text-body my-4" /></>
              ))}

            </div>
          }
            

            <div className="main-content flex-1 p-2 pl-0 overflow-y-auto h-[70vh]">
              {loadJournals && 
                <div className="flex items-center justify-center rounded-lg bg-opacity-80">
                  <Spinner className="text-primary" size={1.5} />
                </div>
              }
              {!loadJournals && !editorOpen && (entries.length  === 0) ? (
                <div className="text-center mx-10 my-20 text-body dark:text-white" onClick={handleNewEntry}>
                  <img src="/images/FileText.svg" alt="Empty Journal" className="w-16 h-16 max-md:w-12 max-md:h-12 mx-auto" />
                  <h3 className="text-wrap mx-auto max-md:text-sm">Ready to start journaling? Tap to create<br/> your first entry!</h3>
                </div>
              ) : !editorOpen ?
                <ListEntries entries={entries} onJournalClick={handleJournalClick} sortByAsc={sortAsc} /> : <></>
              }
            </div>
          </div>
        </div>)
      }

      {editorOpen && !loadJournals &&
        <div className={`w-full h-full max-md:px-2 max-md:mx-0 max-md:pt-3 journal-desktop ${toggleFullScreen ? 'md:ml-32 md:mr-32 ': 'md:mr-2  pl-4'} max-md:pl-2 max-md:border-l-0 md:overflow-y-auto md:pb-32`}>
          <div className="flex justify-between items-center mb-6 md:mb-0 fixed-h">
          {/* Close Icon  */}
            <button className="text-gray-600 dark:text-white cursor-pointer max-md:hidden" onClick={handleViewAllEntries}>
              {/* Close X icon */}
              <FaXmark className="text-gray-600 dark:text-white w-8 h-8 " />
              
            </button>
            <img className=" md:hidden" alt="CaretLeft" src={`/images/${isDarkMode ? 'darkCaretLeft' : 'CaretLeft'}.svg`} onClick={handleViewAllEntries} />
          </div>
          <div className="flex justify-between items-center mb-6 md:mb-0 fixed-h">
            
            <div className=" mb-4">
              
              <p className='text-gray-600 dark:text-white'>Created @ {format(selectedDate || defaultDate, 'EEE, d MMM yyyy')}</p>
            </div>
            <div className='relative' ref={dropdownRef}>
              <div className="text-gray-600 dark:text-white cursor-pointer" 
                onClick={handleDropdown}
                >
                {/* <FaEllipsisH className="text-gray-600 cursor-pointer w-8 h-6" /> */}
                <img className='text-gray-600 dark:text-white cursor-pointer' src={`/images/${isDarkMode ? 'settings/darkThreeDots.svg': 'DotsThree.svg'}`} alt='dots' />
              </div>
              {isDropdownVisible && (
              <div className='dropdown-menu dark:bg-bgDark'>
                <ul>
                  {/* <li ><FiShare2 className='dropdown-icon' />Share Entry</li> */}
                  <li className='pointer-events-none text-gray-200 dark:text-gray-500'><img src={`/images/settings/${isDarkMode ? 'darkExport.svg' : 'Export.svg'}`} alt="Export" />Export Entry</li>
                  <li className={`${!currentJournal.id ? 'pointer-events-none text-gray-200 dark:text-white' : ''} pl-5`} onClick={()=> handleDeleteEntry(currentJournal)}><img src={`/images/${isDarkMode ? 'darkTrash.svg' : 'Trash.svg'}`} alt="" />Delete Entry</li>
                </ul>
              </div>
              )}
            </div>
            
          </div>
          {/* For Mobile view only */}
          {/* <div className="flex justify-start md:hidden items-center mb-4 md:mb-0"> */}
            
            {/* Show Journal Date without using DatePicker*/}
            {/* <p className='text-gray-600 dark:text-white'>Created @{ format(selectedDate || defaultDate, 'EEE, d MMM yyyy')}</p> */}

          {/* </div> */}
          {/* ---- Date Picker */}
          
          {/* React Emoji Picker for mood */}
          <div className="flex flex-col items-start mb-3 relative">
            <img src={`${currentJournal.mood}`} alt="Emoji" className="w-12 h-12 " onClick={() => setIsMoodPickerOpen(!isMoodPickerOpen)} />
            {isMoodPickerOpen && <Picker onEmojiClick={handleMoodChange} className="absolute top-2" /> }
          </div>
          {/* ---- Emoji Picker */}
          <input
            type="text"
            value={currentJournal.title}
            onChange={handleTitleChange}
            placeholder="Title"
            className="w-full dark:bg-bgDark dark:text-white text-header placeholder:text-gray-placeholder text-4xl leading-3 max-md:text-2xl font-bold max-md:font-[600] focus:outline-none transition duration-150 ease-in-out outline-none mb-2"
          />
          <div className='flex flex-1 gap-2 items-start mb-3'>
            <FaTag className='relative top-3' />
            <p className='mr-4 relative top-2'>Tags</p>
            <TagSelector journal={currentJournal} onTagChange={onTagChange} />
          </div>
          
          <TipTapEditor onContentChange={handleEditorContent} journal={currentJournal} isEditing={true} />

        </div>
      }
    </div>
  );
  
};

const CustomInput = React.forwardRef(({ value, onClick }: any, ref: any) => (
  <div className="flex items-center">
    <button
      className="text-gray-600 bg-transparent focus:outline-none flex items-center dark:text-body"
      onClick={onClick}
      ref={ref}
    >
      {value || 'Select a date'}
    </button>
    <FaAngleDown
      className="ml-2 text-gray-600 cursor-pointer dark:text-body"
      onClick={onClick}
    />
  </div>
));

export default JournalApp;