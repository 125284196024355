import { useState, useEffect } from "react";
import { LoginEntry, BaseResponse } from '../utils/api';
import { fetchLogin } from "../utils/login";  

const useLogin = (loginData: LoginEntry | null) => {
  const [baseResponse, setBaseResponse] = useState<BaseResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if(!loginData) return;

    const getLoginResponse = async (loginData: LoginEntry) => {
      try {
        setIsLoading(true);
        const response = await fetchLogin(loginData);
        // console.log("Login response:", response);
        
        setBaseResponse(response);

      } catch (error) {
        console.error("Error logging in:", error);
      } finally {
        setIsLoading(false);
      }
    };

      getLoginResponse(loginData);
    
   
  }, [loginData]);

  return { isLoading, baseResponse };
};

export default useLogin;