// src/components/Features.js
import { motion } from 'framer-motion';
import React from 'react';

const FeaturesSection: React.FC = () => {

  const features = [
    {
      title: "Express Yourself",
      description: "Write freely and explore your thoughts and emotions with an intuitive interface designed for effortless expression.",
      icon: 'express-yourself-icon.png',
      alt: "Express Yourself",
    },
    {
      title: "Visualize Your Thoughts",
      description: "Incorporate images or videos into your journal to enhance your entries and provide a visual representation of your ideas.",
      icon: 'visualize-thoughts-icon.png',
      alt: "Visualize Your Thoughts",
    },
    {
      title: "Multi-Device Sync",
      description: "Easily journal across all your devices through your browser. Your entries are always updated and accessible, no matter where you are.",
      icon: 'multi-device-sync-icon.png',
      alt: "Multi-Device Sync",
    },
  ];


  return (
    <section className="pb-16 px-4 md:px-60 bg-white dark:bg-bgDark">
      <div className="container mx-auto text-center">
        {/* Title Section */}
        
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
         className="text-center mb-12 w-3/4 max-md:w-full mx-auto"
         >
          <h2 className="text-3xl font-bold">Finding the <span className="text-primary">right words</span> can be tough.<br/>Toice makes it easy for you.</h2>
          <p className="text-black pt-3">Capture your thoughts and experiences with Toice’s intuitive tools, designed to help you express yourself with clarity and ease.</p>
        </motion.div>

        {/* Feature Icons Section */}
        <div className="flex flex-col md:flex-row justify-center gap-10 mt-20 max-md:px-24">
            
          {features.map((feature, idx) => (
            <motion.div
              key={idx}
              className="flex flex-col items-center"
              whileHover={{ scale: 1.05 }}
            >
              <img
                src={`/images/icons/${feature.icon}`}
                alt={feature.alt}
                className="w-16 h-16 mb-4"
              />
              <h3 className="text-xl font-semibold text-black dark:text-white mb-2">{feature.title}</h3>
              <p className="text-black dark:text-gray-300 text-center">
                {feature.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
